<div class="modal-container">
  <div class="modal-header">
    <h4 class="modal-title" style="font-size: 40px;">Horário de funcionamento</h4>
  </div>
  <div class="modal-body">
    <div class="mt-4" *ngIf="horarios.length > 0; else noHorarios">
      <div class="row" *ngFor="let dia of diasSemana">
        <div class="col-md-12 text-center">
          <div class="title" style="display: inline-block;">
            <strong>{{ dia.name }}:</strong>
          </div>
          <div class="content" style="display: inline-block;">
            {{ getHorarioPorDia(dia.value) }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #noHorarios>
      <div class="row">
        <div class="col-md-12 text-center content">
          <p><strong>Expediente não informado</strong></p>
        </div>
      </div>
    </ng-template>
  </div>
  <button class="btn btn-dark rounded-pill float-right mb-3 col-md-2" (click)="closeModal()">
    Fechar
  </button>
</div>
