import { Util } from './../../../shared/utils/util';
import { Component, OnInit } from '@angular/core';

declare var $: any;

@Component({
  selector: 'app-layout-auth',
  templateUrl: './layout-auth.component.html',
  styleUrls: ['./layout-auth.component.scss']
})
export class LayoutAuthComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
    $('body').addClass('sidebar-mini layout-fixed');
    Util.setColorThemeV2();
  }
}
