import { InformacoesOrgaoPdfDto } from "./informacoes-orgao-pdf-dto";
import { InformacoesPlanoPdfDto } from "./informacoes-plano-pdf-dto";

export class DetalhamentoOrgaopdfDto {
  public informacoesOrgaoPdf: InformacoesOrgaoPdfDto;
  public informacoesPlanoPdf: InformacoesPlanoPdfDto;

  constructor(object: any) {
      if (object) {
          this.informacoesOrgaoPdf                       = object.informacoesOrgaoPdf;
          this.informacoesPlanoPdf                       = object.informacoesPlanoPdf;
      }
  }
}
