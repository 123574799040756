<nav class="main-header navbar navbar-expand navbar-custom navbar-dark">
  <div class="container-navbar">
    <ul class="navbar-nav menu-icon">
      <li class="nav-item" style="margin-top: -5px; top: -5px">
        <a class="nav-link menu-item" data-widget="pushmenu" href="#">
          <div class="nav-icon open">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </a>
      </li>
    </ul>
    <div class="navbar-nav collapse navbar-collapse">
      <div class="title-nav">
        <h4><strong>Dashboard da Transformação Digital</strong></h4>
      </div>
      <li class="nav-item dropdown mr-2 mt-2">
        <label>Você está no órgão: {{ nomeOrgao }}</label>
      </li>

      <div *ngIf="this.usuarioAdmin">
        <li class="nav-item dropdown mr-2">
          <button type="button" (click)="openModalSelecionarOrgao(true, false)" class="close">
            <i class="fas fa-exchange-alt close-img"></i>
          </button>
        </li>
      </div>

      <li class="nav-item dropdown mt-2" >
        <a class="notMobile nav-link text-primary vibrate-hover" data-toggle="dropdown" href="#">
          <div class="pulse" *ngIf="notificationLength()"></div>
          <i class="far fa-bell fa-lg icon-animation"></i>
        </a>

        <a class="mobile nav-link text-primary vibrate-hover" [routerLink]="['/notificacao']"  href="#">
          <div class="pulse" *ngIf="notificationLength()"></div>
          <i class="far fa-bell fa-lg icon-animation"></i>
        </a>

        <div class="dropdown-menu dropdown-menu-lg dropdown-menu-right" style="max-width: 500px; max-height: 400px; overflow: auto;">
          <span class="dropdown-item dropdown-header">{{ totalNotification.length }}
            Notificações</span>
            <div class="dropdown-divider"></div>
          <div *ngIf="notificationLength(); else empty">
            <div *ngFor="let notification of notificationNotView">
              <div  class="dropdown-item" (click)="readNotification(notification)">
                <p style="word-wrap: break-word;"><i class="fas fa-envelope mr-2"></i>{{ notification.mensagem }}</p>
                <span class="float-right text-muted text-sm">{{ notification.tempo }} {{ notification.typeTemp ? notification.typeTemp : 'minutos' }}</span>
              </div>
            </div>
          <br>
          <div class="dropdown-divider"></div>
          <a [routerLink]="['/notificacao']" class="dropdown-item dropdown-footer">Ver todas notificações</a>
        </div>
        <ng-template #empty>
          <span class="dropdown-item dropdown-header">  Não existem notificações a serem visualizadas</span>
        </ng-template>
        </div>

      </li>

      <li class="nav-item dropdown ml-2">
        <a *ngIf="hiddenComponenteWithAll([RestricaoUsuario.USUARIO_CREATE_UPDATE])" [routerLink]="['/configuracao/usuario/editar', usuarioLogado.id]" routerLinkActive="router-link-active"  class="nav-link">
          <i class="fas fa-user"></i>
        </a>
      </li>

      <li class="nav-item dropdown ml-2">
        <a class="nav-link text-primary" (click)="logout()" title="sair" data-toggle="dropdown" href="#">
          <i class="fas fa-sign-out-alt"></i>
        </a>
      </li>
    </div>
  </div>

  <div class="d-flex col-12 p-0">
    <div class="col bg-danger" style="height: 4px;">
    </div>
    <div class="col bg-warning" style="height: 4px;">
    </div>
    <div class="col bg-success" style="height: 4px;">
    </div>
    <div class="col bg-primary" style="height: 4px;">
    </div>
  </div>

</nav>





