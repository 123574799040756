import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { Util } from 'src/app/shared/utils/util';
import { ModalViewServicoComponent } from './modal-view-servico/modal-view-servico.component';
import { AutocompleteDto } from 'src/app/core/dto/auto-complete-dto';
import { FormBase } from 'src/app/shared/utils/form-base';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { AutoCompleteService } from 'src/app/core/services/auto-complete.service';
import { AtivoInativo } from 'src/app/core/enums/plano/ativo-inativo.enum';

@Component({
  selector: 'app-modal-servico',
  templateUrl: './modal-servico.component.html',
  styleUrls: ['./modal-servico.component.scss']
})
export class ModalServicoComponent extends FormBase implements OnInit {

  public orgaos;
  public servicos;
  public formaPrestacao;
  public titleModal;
  private orgaoUrl: string = 'orgao';
  public servicoUrl: string = 'servico-modal';
  public orgaoId;
  public ativoInativo = new AtivoInativo().getAllForSelect();
  
  public servicosList: AutocompleteDto[] = new Array<AutocompleteDto>();
  public orgaoList: AutocompleteDto[] = new Array<AutocompleteDto>();

  constructor(
    private activeModal: NgbActiveModal,
    private chartService: ChartService,
    public modalService: NgbModal,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private autoCompleteService: AutoCompleteService,
  ) {
    super(
      router, activatedRoute
    );
   }

  ngOnInit(): void {
    this.createForm();
    this.getServicos();
  }

  public createForm(){
    this.form = this.formBuilder.group({
      orgaoId: new FormControl(''),
      servicoId: new FormControl(''),
      ativo: new FormControl('')
    });
  }

  public getServicos() {
    this.chartService.getServicos(this.getQueryParams()).subscribe((response) => {
      this.checkFormaPrestacaoServicos(response);
    });
  }

  public getQueryParams(): URLSearchParams {
    const params: URLSearchParams = Util.createFilter();
    if(this.form.value.orgaoId){
      params.append('orgaoId', this.form.get('orgaoId').value.id);
    }
    if(this.form.value.servicoId){
      params.append('servicoId', this.form.get('servicoId').value.id);
    }
    if(this.form.value.ativo){
      params.append('ativo', this.form.value.ativo == 'Inativo' ? 'false' : 'true')
    }
    return params;
  }

  public getServicosOnline() {
    this.chartService.getServicosOnline(this.getQueryParams()).subscribe((response) => {
      this.servicos = response;
    });
  }

  public getServicosParcialmenteOnline() {
    this.chartService.getServicosParcialmenteOnline(this.getQueryParams()).subscribe((response) => {
      this.servicos = response;
    });
  }

  public getServicosPresenciais() {
    this.chartService.getServicosPresenciais(this.getQueryParams()).subscribe((response) => {
      this.servicos = response;
    });
  }

  public getServicosTransformados() {
    this.chartService.getServicosTransformados(this.getQueryParams()).subscribe((response) => {
      this.servicos = response;
    });
  }

  public checkFormaPrestacaoServicos(response) {
    if (this.formaPrestacao === 'online') {
      this.getServicosOnline();
      this.titleModal = 'Serviços Online';
    }
    if (this.formaPrestacao === 'parcialmente online') {
      this.getServicosParcialmenteOnline();
      this.titleModal = 'Serviços Parcialmente Online';
    }
    if (this.formaPrestacao === 'presencial') {
      this.getServicosPresenciais();
      this.titleModal = 'Serviços Presenciais';
    }
    if (this.formaPrestacao === 'transformado digitalmente') {
      this.getServicosTransformados();
      this.titleModal = 'Serviços Transformados Digitalmente';
    }
    if (!this.formaPrestacao) {
      this.servicos = response;
      this.titleModal = 'Serviços';
    }
  }

  public closeModal(): void {
    this.activeModal.close();
  }
  public viewServico(item?: any) {
    const modalRef = Util.openModal(this.modalService, ModalViewServicoComponent, 'lg');
    modalRef.componentInstance.servicoId = item.servicoId;
  }

  public getAllOrgao() {
    const params = Util.createFilterStatusActive();
    return this.filterAutoSelect(this.orgaoUrl, params);;
  }

  public getAllServico() {
    const params = Util.createFilterStatusActive();
    return this.filterServicoAutoSelect(this.servicoUrl, params);;
  }

  private filterAutoSelect(url: string, params: URLSearchParams): void {
    this.autoCompleteService.getForSelect(url, params)
      .subscribe(
        (response) => {
          if (url === this.orgaoUrl) {
            this.orgaoList = response.body.data.map(item => new AutocompleteDto(item));
          }
        }
      );
  }

  private filterServicoAutoSelect(url: string, params: URLSearchParams): void {
    this.autoCompleteService.getForSelect(url, params)
      .subscribe(
        (response) => {
          if (url === this.servicoUrl) {
            this.servicosList = response.body.data.map(item => new AutocompleteDto(item));
          }
        }
      );
  }

  public searchOrgao(event) {
    if (event.query) {
      const params = Util.createFilterStatusActive();
      params.append('descricao', event.query.toUpperCase());
      return this.filterAutoSelect(this.orgaoUrl, params);
    }
  }

  public searchServico(event) {
    if (event.query) {
      const params = Util.createFilterStatusActive();
      params.append('descricao', event.query.toUpperCase());
      return this.filterServicoAutoSelect(this.servicoUrl, params);
    }
  }

  clearFilter(): void {
    this.form.reset();
    this.getServicos();
  }

  submit(): void {
    this.getServicos();
  }

  get verificaVisao() {
    var orgao = Util.getOrgaoSession()
    return this.orgaoLogadoId === '00000000-0000-0000-0000-000000000000' || this.orgaoLogadoId === null
  }

  get orgaoLogadoId() {
    return Util.getOrgaoSession()
  }

}
