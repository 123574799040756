import { RestricaoGenericos } from './restricao-generic';

export const restricaoPerfil = 'PERFIL_';


export const RestricaoPerfil = {
  descricao: 'Perfil',

  PERFIL_ALL             : `${restricaoPerfil}${RestricaoGenericos.ALL}`,
  PERFIL_REMOVE          : `${restricaoPerfil}${RestricaoGenericos.REMOVE}`,
  PERFIL_CREATE_UPDATE   : `${restricaoPerfil}${RestricaoGenericos.CREATE_UPDATE}`,
  PERFIL_VIEW            : `${restricaoPerfil}${RestricaoGenericos.VIEW}`,
  PERFIL_ACTIVE_INACTIVE : `${restricaoPerfil}${RestricaoGenericos.ACTIVE_INACTIVE}`,
};
