import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { HorarioServicoDto } from 'src/app/core/dto/orgao/view/horario-servico.dto';
import { DiasSemana } from 'src/app/core/enums/orgao/dias-semana.enum';

@Component({
  selector: 'app-modal-horarios-funcionamento',
  templateUrl: './modal-horarios-funcionamento.component.html',
  styleUrls: ['./modal-horarios-funcionamento.component.scss']
})
export class ModalViewHorariosComponent implements OnInit {

  public horarios: HorarioServicoDto[];
  public diasSemana = new DiasSemana().getAll();
  constructor(
    private activeModal: NgbActiveModal,
  ) { }

  ngOnInit(): void {
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  getHorarioPorDia(dia: string): string {
    const horarioDia = this.horarios.find((horario) => horario.dia === dia);
    if (horarioDia) {
      return `${horarioDia.horarioInicio} - ${horarioDia.horarioFim}`;
    } else {
      return 'Sem expediente';
    }
  }
}
