import { Status } from 'src/app/core/enums/plano/status.enum';

export class AcoesDiretrizesListDto {
  public id: string;
  public titulo: string;
  public dataCriacao: string;
  public ativo: boolean;
  public quantidadeServicos: number;
  public status: Status;
  public possibilitaEdicao: boolean;

  constructor(object?: any) {
    if (object) {
      this.id                 = object.id;
      this.titulo             = object.titulo;
      this.dataCriacao        = object.dataCriacao;
      this.ativo              = object.ativo;
      this.quantidadeServicos = object.quantidadeServicos;
      this.status             = object.status;
      this.possibilitaEdicao  = object.possibilitaEdicao;
    }
  }
}

