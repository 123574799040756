export class EtapaInfoDto {
  public orgaoId: string;
  public situacaoCadastral: boolean;
  public historico: boolean;
  public competencia: boolean;
  public servico: boolean;
  public membrosComissao: boolean;
  public acoesDiretrizes: boolean;

  constructor(object?: any) {
    if (object) {
      this.orgaoId = object.orgaoId;
      this.situacaoCadastral = object.situacaoCadastral;
      this.historico = object.historico;
      this.servico   = object.servico;
      this.competencia   = object.competencia;
      this.membrosComissao = object.membrosComissao;
      this.acoesDiretrizes = object.acoesDiretrizes;
    }
  }
}
