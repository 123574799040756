import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { OrgaoDetalhesPdf } from '../orgao-dashboard-detalhes-pdf';
import { Util } from 'src/app/shared/utils/util';
import { FormBase } from 'src/app/shared/utils/form-base';
import { AutocompleteDto } from 'src/app/core/dto/auto-complete-dto';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl } from '@angular/forms';
import { AutoCompleteService } from 'src/app/core/services/auto-complete.service';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-orgao-plano-transformacao',
  templateUrl: './modal-orgao-plano-transformacao.component.html',
  styleUrls: ['./modal-orgao-plano-transformacao.component.scss'],
})
export class ModalOrgaoPlanoTransformacaoComponent extends FormBase implements OnInit {

  public orgaos;
  public orgaosFiltrados;

  private orgaoUrl: string = 'orgao';
  public orgaoList: AutocompleteDto[] = new Array<AutocompleteDto>();

  constructor(
    private activeModal: NgbActiveModal,
    private chartService: ChartService,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private autoComplete: AutoCompleteService,
    public router: Router
  ) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.orgaosFiltrados = this.orgaos;
    this.createFormGroup();
  }

  public createFormGroup(): void {
    this.form = this.formBuilder.group({
      orgaoId: new FormControl('')
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public downloadPdf(item) {
    this.chartService.getOrgaoPdf(item.id)
      .toPromise()
      .then(res => {
        OrgaoDetalhesPdf.gerarPdfOrgao(res);
      });
  }

  public submit(): void {
    if (!this.enableShipping()) {
      this.orgaosFiltrados = this.orgaos.filter(item => item.id === this.form.controls.orgaoId.value.id);
    }
  }


  public clearFilter(): void {
    this.form.patchValue({
      orgaoId: ''
    });
    this.orgaosFiltrados = this.orgaos
  }

  public getAllOrgao() {
    const params = Util.createFilterStatusActive();
    return this.filterOrgaoAutoSelect(this.orgaoUrl, params);
  }

  public searchOrgao(event) {
    if (event.query) {
      const params = Util.createFilterStatusActive();
      params.append('descricao', event.query.toUpperCase());
      return this.filterOrgaoAutoSelect(this.orgaoUrl, params);
    }
  }

  private filterOrgaoAutoSelect(url: string, params: URLSearchParams): void {
    this.autoComplete.getForSelect(url, params)
      .pipe(
        map(
          (response) => response.body.data.map(
            item => new AutocompleteDto(item)
          )
        )).subscribe(
          (response) => {
            if (url === this.orgaoUrl) {
              this.orgaoList = response;
            }
          }
        );
  }
}
