import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { PublicoAlvoEnum } from 'src/app/core/enums/enum-orgao-servico/publico-alvo.enum';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { Util } from 'src/app/shared/utils/util';
import { ModalViewHorariosComponent } from './modal-horario-funcionamento/modal-horarios-funcionamento.component';
import { ModalUnidadesFisicasComponent } from './modal-unidades-fisicas/modal-unidades-fisicas.component';
import { ModalViewUnidadeFisicaComponent } from './modal-view-unidade-fisica/modal-view-unidade-fisica.component';
import { TipoTaxaNameEnum } from 'src/app/core/enums/servico/tipo-taxa-name.enum.ts';

@Component({
  selector: 'app-modal-view-servico',
  templateUrl: './modal-view-servico.component.html',
  styleUrls: ['./modal-view-servico.component.scss']
})
export class ModalViewServicoComponent implements OnInit {

  public publicosAlvoDescription: Array<string> = [];
  public servicoId: any;
  public servico;
  public tipoTaxaSobConsulta = 'Sob Consulta';
  public formaPrestacaoUrl = false;

  constructor(
    private activeModal: NgbActiveModal,
    private chartService: ChartService,
    public modalService: NgbModal
  ) { }

  ngOnInit(): void {
    this.getServico(this.servicoId);
  }

  public getServico(item: any) {
    this.chartService.getDetalharServico(item).subscribe((response) => {
      this.servico = response;
      this.servico.tipoTaxa = new TipoTaxaNameEnum().getName(response.tipoTaxa);
      this.publicosAlvoDescription = this.convertePublicoAlvo(this.servico.publicoAlvo);
      this.formaPrestacaoUrl = this.servico.formaPrestacao.includes('Online') || this.servico.formaPrestacao.includes('Parcialmente Online')
    });
  }

  convertePublicoAlvo(chaves: Array<string>): Array<string> {
    return this.servico.publicoAlvo.map(x => {
      const itemEnum = PublicoAlvoEnum.find(item => item.key === x);
      return itemEnum ? itemEnum.value : '';
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public openHorarios(item?: any) {
    const modalRef = Util.openModal(this.modalService, ModalViewHorariosComponent, 'md');
    modalRef.componentInstance.horarios = this.servico.horarios;
  }

  public openUnidadesFisicas() {
    const modalRef = Util.openModal(this.modalService, ModalUnidadesFisicasComponent, 'md');
    modalRef.componentInstance.servicoId = this.servicoId;
    modalRef.componentInstance.orgao = this.servico.orgaoNome;
  }

  public viewUnidadeFisica(item: any) {
    const modalRef = Util.openModal(this.modalService, ModalViewUnidadeFisicaComponent, 'md');
    modalRef.componentInstance.unidadeFisica = item;
  }

  formatValor(item: any) {
    return Util.getFormattedPrice(item);
  }
}
