import { Component, OnChanges, OnInit, SimpleChanges } from '@angular/core';

import * as signalR from '@microsoft/signalr';

import { environment } from 'src/environments/environment';
import { NotificacaoService } from 'src/app/modules/notificacao/service/notificacao.service';
import { NotificacaoListDto } from 'src/app/core/dto/configuracao/notificacao/notificacao-list.dto';
import { Util } from 'src/app/shared/utils/util';
import { ActivatedRoute, Router } from '@angular/router';
import { NotificacoesEnum } from 'src/app/core/enums/notificacoes/notificacoes.enum';
import { Guid } from 'guid-typescript';
import { FormBase } from 'src/app/shared/utils/form-base';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { NavbarAuthModalComponent } from './navbar-auth-modal/navbar-auth-modal.component';
import { LocalStorageService } from 'src/app/core/services/local-storage-service';
import { RestricaoUsuario } from '../../../../enums/restricoes/restricao-usuario';

@Component({
  selector: 'app-navbar-auth',
  templateUrl: './navbar-auth.component.html',
  styleUrls: ['./navbar-auth.component.scss']
})
export class NavbarAuthComponent extends FormBase implements OnInit{

  public connection: signalR.HubConnection;
  public notificationNotView: NotificacaoListDto[] = [];
  public totalNotification: NotificacaoListDto[] = [];
  private _notificationView: any[] = [];
  public minutos: number;
  public usuarioAdmin = false;
  public nomeOrgao = '';
  public orgaoId: any;
  public usuarioLogado: any;
  public RestricaoUsuario = RestricaoUsuario;

  constructor(
    private notificacaoService: NotificacaoService,
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public modalService: NgbModal,
    private localStorageService: LocalStorageService,
  ) {
    super(router, activatedRoute);
    this.createConnection();
    this.startConnection();
    this.bindEvents();
    this.getOrgao();
  }

  ngOnInit(): void {
    this.getOrgaoId();
    this.getAllNotificationNotView();
    this.verificarUsuarioAdmin();
    this.changeLocalStorage();
    this.usuarioLogado = Util.getUsuarioLogado();
  }

  public getAllNotificationNotView(): void {
    const params = Util.createFilter();
    params.set('lida', 'false');
    this.orgaoId ?
    params.set('orgaoId', this.orgaoId) :
    params.set('orgaoId', '00000000-0000-0000-0000-000000000000')
    this.notificacaoService.getByFilter(params).subscribe((res) => {
      this.totalNotification = res;
      this.notificationNotView = res.sort((a, b) => b.dataNotificacao.localeCompare(a.dataNotificacao)).slice(0, 5);
      this.notificationNotView.map((el) => {
        const dataAtual = new Date();
        const dataCreate = new Date(el.dataNotificacao);
        el.tempo = Math.floor((Number(dataAtual) - Number(dataCreate)) / 60000);
        if (el.tempo > 60) {
          el.tempo = Math.floor(el.tempo / 60);
          el['typeTemp'] = el.tempo > 1 ? 'horas' : 'hora';
        }
      });
    });
  }

  public createConnection() {
    this.connection = new signalR.HubConnectionBuilder()
      .withUrl(`${environment.apiUrl}hub/notificacoes`)
      .build();
  }

  public startConnection() {
    this.connection.start()
      .then(() => {
        this.connection.invoke('Join', this.usuarioLogado.cpf);
      })
      .catch(e => {
        setTimeout(() => this.startConnection(), 5000);
        console.log('Erro na conexao');
      });
  }

  public bindEvents() {
    // Recebe mensagens do servidor
    this.connection.on('notification', (payload: string) => {
      const message = JSON.parse(payload);
    });

    this.connection.on('user_change_perfil', (payload: string) => {
      const message = JSON.parse(payload);
    });

    this.connection.on('change_perfil', (payload: string) => {
      const message = JSON.parse(payload);
    });

    this.connection.on('orgao_finish', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('plano_create', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('plano_aproved', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('plano_finished', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('plano_reproved', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('servico_sent', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('servico_aproved', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('servico_reproved', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('servico_desvinculado', (payload: string) => {
      const message = JSON.parse(payload);
      this.getAllNotificationNotView();
    });

    this.connection.on('plano_change_status', (payload: string) => {
      const message = JSON.parse(payload);
      Util.setStatusPlanoAtivo(message);
      this.localStorageService.update();
    });
  }

  public notificationLength(): boolean {
    if (this.notificationNotView) {
      return this.notificationNotView.length > 0 ? true : false;
    }
  }

  public confirmReading(item: string | Guid) {
   this._notificationView.push(item);
   this.notificacaoService.putBody(this._notificationView).subscribe((res) => {
     this.getAllNotificationNotView();
   });
  }

  public readNotification(item: NotificacaoListDto): void  {
    this.confirmReading(item.id);
    if (item.tipoNotificacaoId === NotificacoesEnum.ORGAO_FINALIZADO) {
      this.router.navigate(['/orgao/visualizar/', item.tabelaModificadaId]);
    }

    if (item.tipoNotificacaoId === NotificacoesEnum.PLANOS_FINALIZADO) {
      this.router.navigate(['/plano/']);
    }

    if (item.tipoNotificacaoId === NotificacoesEnum.ENVIO_CONFERENCIA_PLANO) {
      this.router.navigate(['/plano']);
    }

    if (item.tipoNotificacaoId === NotificacoesEnum.FINALIZACAO_TRANSFORMACAO_DIGITAL) {
      this.router.navigate(['/plano']);
    }

    if (item.tipoNotificacaoId === NotificacoesEnum.REPROVACAO_TRANSFORMACAO_DIGITAL) {
      this.router.navigate(['/plano']);
    }

    if (item.tipoNotificacaoId === NotificacoesEnum.APROVACAO_TRANSFORMACAO_DIGITAL) {
      this.router.navigate(['/plano']);
    }
    if (item.tipoNotificacaoId === NotificacoesEnum.ENVIO_SUBMISSAO_SERVICO) {
      this.router.navigate(['/plano']);
    }
    if (item.tipoNotificacaoId === NotificacoesEnum.SERVICO_DESVINCULADO) {
      this.router.navigate(['/plano']);
    }
  }

  getOrgao(): string {
    this.nomeOrgao = Util.getOrgaoNameSession() ?? 'Sem órgão vinculado';
    return this.nomeOrgao;
  }

  getOrgaoId(): string {
    this.orgaoId = Util.getOrgaoSession();
    return this.orgaoId;
  }

  verificarUsuarioAdmin(): boolean {
    this.usuarioAdmin = Util.verificaAdm();

    return this.usuarioAdmin;
  }

  public openModalSelecionarOrgao(modalInicial: boolean, modalBotao: boolean): void {
    const modal = Util.openModal(this.modalService, NavbarAuthModalComponent, 'lg');
    modal.componentInstance.selecionarOrgaoInicial = modalInicial;
    modal.componentInstance.selecionarOrgaoBotao = modalBotao;

    if (!modalBotao) {
      modal.componentInstance.botaoSair = true;
    } else {
      modal.componentInstance.botaoSair = false;
    }
  }

  changeLocalStorage() {
    this.localStorageService.localStorage.subscribe((resp) => {
      if (resp) {
        this.getOrgao();
      }
    });
  }

  public logout(): void {
    this.router.navigate(['/transparencia']).then(() => {
      window.location.reload();
    });
  }
}
