
export const PublicoAlvoEnum = [
  {
      key: 'CIDADAO',
      value: 'Cidadão'
  },
  {
      key:'PRODUTOR_RURAL',
      value: 'Produtor Rural',
  },
  {
      key: 'ORGAO_ENTIDADE_PUBLICA',
      value: 'Orgãos e Entidades Públicas'
  },
  {
      key: 'EMPRESA',
      value: 'Empresa',
  },
  {
      key: 'SERVIDOR_PUBLICO',
      value: 'Servidor Público'
  },
  {
      key: 'ENTIDADE_SEM_FIM_LUCRATIVO',
      value: 'Entidades sem fins lucrativos'
  }
];

export class  EnumPublicoAlvo  {

  public value: string;

  constructor(publicoAlvo?) {
    if (publicoAlvo) {
    this.value = publicoAlvo.value;
      }
  }

  getAll(): any[] {
      return [...PublicoAlvoEnum];
  }
}
