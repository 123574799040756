import { EnumBase } from '../../models/enum-base.model';

export class TipoTaxaNameEnum extends EnumBase {
  constructor() {
      super([
          { value: 'SOB_CONSULTA', name: 'Sob Consulta' },
          { value: 'TAXA_TARIFA', name: 'Taxa/Tarifa' },
          { value: 'GRATUITO', name: 'Gratuito' },
      ], 'Status');
  }
}
