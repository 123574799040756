<div class="table-responsive p-0">
  <table
    #table
    class="table table-hover table-cutom table-sm table-bordered mt-2 mb-2"
  >
    <thead>
      <ng-container *ngTemplateOutlet="appTableHeader"></ng-container>
    </thead>
    <tbody>
      <ng-container
        *ngFor="
          let item of items
            | paginate
              : {
                  itemsPerPage: itemsPerPage,
                  currentPage: pageCurrent,
                  totalItems: items.length
                };
          let index = index
        "
      >
        <ng-container
          *ngTemplateOutlet="
            appTableBody;
            context: { $implicit: item, index: index }
          "
        ></ng-container>
      </ng-container>
      <ng-container *ngIf="items.length === 0">
        <ng-container *ngTemplateOutlet="appTableEmpty"></ng-container>
      </ng-container>
    </tbody>
  </table>
</div>
<app-paginator-custom
  *ngIf="paginator"
  [stylesClass]="classPaginator"
  [items]="items"
  [itemsPerPage]="itemsPerPage"
  [messageTotal]="messageTotal"
  [qtdTotal]="qtdTotal"
  [viewTotal]="viewTotal"
  (pageChange)="setCurrentPage($event)"
>
</app-paginator-custom>
