import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LocalStorageService {

  private localStorageSubject: Subject<boolean> = new Subject();

  public localStorage = this.localStorageSubject.asObservable();


  update() {
    this.localStorageSubject.next(true);
  }

}
