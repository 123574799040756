import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { ModalModule } from 'ngx-bootstrap/modal';
import { ngxLoadingAnimationTypes, NgxLoadingModule } from 'ngx-loading';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxPermissionsModule } from 'ngx-permissions';
import { ProgressBarModule } from 'primeng';

import {
  ModalOrgaoPlanoTransformacaoComponent,
} from '../modules/landing-page/pages/landing-page/modal-orgao-plano-transformacao/modal-orgao-plano-transformacao.component';
import { ModalOrgaoComponent } from '../modules/landing-page/pages/landing-page/modal-orgao/modal-orgao.component';
import { ModalServicoComponent } from '../modules/landing-page/pages/landing-page/modal-servico/modal-servico.component';
import {
  ModalViewHorariosComponent,
} from '../modules/landing-page/pages/landing-page/modal-servico/modal-view-servico/modal-horario-funcionamento/modal-horarios-funcionamento.component';
import {
  ModalViewServicoComponent,
} from '../modules/landing-page/pages/landing-page/modal-servico/modal-view-servico/modal-view-servico.component';
import { AlertCustomComponent } from './components/alert-custom/alert-custom.component';
import { CardConferenciaComponent } from './components/card-conferencia/card-conferencia.component';
import { CardFormComponent } from './components/card-form/card-form.component';
import { CardMobileComponent } from './components/card-mobile/card-mobile.component';
import { CardComponent } from './components/card/card.component';
import { CircleProgressComponent } from './components/circle-progress/circle-progress.component';
import { GenericTableComponent } from './components/generic-table/generic-table.component';
import { LedComponent } from './components/led/led.component';
import { LoadingCustomInterceptor } from './components/loading-custom/config/loading-custom-interceptor';
import { LoadingCustomComponent } from './components/loading-custom/loading-custom.component';
import { PaginatorCustomComponent } from './components/paginator-custom/paginator-custom.component';
import { ProgressBarLineComponent } from './components/progress-bar-line/progress-bar-line.component';
import { RankingComponent } from './components/ranking/ranking.component';
import { TableBodyDirective } from './components/table-custom/config/table-body.directive';
import { TableEmptyDirective } from './components/table-custom/config/table-empty.directive';
import { TableHeaderDirective } from './components/table-custom/config/table-header.directive';
import { TableCustomComponent } from './components/table-custom/table-custom.component';
import { TemplateModalComponent } from './components/template-modal/template-modal.component';
import { TemplateOrgaoModalComponent } from './components/template-orgao-modal/template-orgao-modal.component';
import { UploadOneFileComponent } from './components/upload-one-file/upload-one-file.component';
import { OnlyCharDirective } from './directives/only-char.directive';
import { OnlyNumberDirective } from './directives/only-number.directive';
import { UpperCaseDirective } from './directives/upper-case.directive';
import { ModalOrgaoRankingComponent } from '../modules/landing-page/pages/landing-page/moda-orgao-ranking/modal-orgao-ranking.component';

@NgModule({
  declarations: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    OnlyCharDirective,
    GenericTableComponent,
    UploadOneFileComponent,
    OnlyCharDirective,
    CardMobileComponent,
    CardComponent,
    CardFormComponent,
    TemplateModalComponent,
    TemplateOrgaoModalComponent,
    LedComponent,
    CardConferenciaComponent,
    CircleProgressComponent,
    ProgressBarLineComponent,
    RankingComponent,
  ],
  entryComponents: [
    ModalOrgaoComponent,
    ModalServicoComponent,
    ModalOrgaoPlanoTransformacaoComponent,
    ModalViewServicoComponent,
    ModalViewHorariosComponent,
    ModalOrgaoRankingComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgxPaginationModule,
    NgxLoadingModule.forRoot({
      animationType: ngxLoadingAnimationTypes.circleSwish,
      backdropBorderRadius: '0px',
      fullScreenBackdrop: true,
      backdropBackgroundColour: 'rgba(0,0,0,0.3)',
      primaryColour: '#007bff',
      secondaryColour: '#0069d9',
      tertiaryColour: '#007bff'
    }),
    BsDropdownModule.forRoot(),
    ReactiveFormsModule,
    NgxPermissionsModule.forRoot(),
    NgxPermissionsModule.forChild(),
    FormsModule,
    ModalModule.forRoot(),
    ProgressBarModule,
    NgxChartsModule
  ],
  exports: [
    AlertCustomComponent,
    LoadingCustomComponent,
    PaginatorCustomComponent,
    TableCustomComponent,
    TableHeaderDirective,
    TableBodyDirective,
    TableEmptyDirective,
    UpperCaseDirective,
    OnlyNumberDirective,
    OnlyCharDirective,
    GenericTableComponent,
    UploadOneFileComponent,
    NgxPermissionsModule,
    CardMobileComponent,
    CardComponent,
    CardFormComponent,
    TemplateModalComponent,
    TemplateOrgaoModalComponent,
    LedComponent,
    CardConferenciaComponent,
    CircleProgressComponent,
    ProgressBarLineComponent,
    RankingComponent    
  ],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: LoadingCustomInterceptor,
      multi: true
    }
  ]
})
export class SharedModule {
  static forRoot(): ModuleWithProviders {
    return {
      ngModule: SharedModule,
      providers: [],
    };
  }
}
