import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormGroup,  FormControl} from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { AutocompleteDto } from 'src/app/core/dto/auto-complete-dto';

import { AutoCompleteService } from 'src/app/core/services/auto-complete.service';
import { v4 as uuidv4 } from 'uuid';
import { FormBase } from 'src/app/shared/utils/form-base';
import { Util } from 'src/app/shared/utils/util';
import { LocalStorageService } from 'src/app/core/services/local-storage-service';
import { AcoesDiretrizesService } from 'src/app/modules/plano/services/acoes-diretrizes.service';
import { ChartService } from 'src/app/modules/home/services/chart.service';


@Component({
  selector: 'app-navbar-auth-modal-form-modal',
  templateUrl: './navbar-auth-modal.component.html',
  styleUrls: ['./navbar-auth-modal.component.scss']
})
export class NavbarAuthModalComponent extends FormBase implements OnInit {

  @Input() public form: FormGroup;
  @Input() public selecionarOrgaoInicial: boolean;
  @Input() public selecionarOrgaoBotao: boolean;
  @Input() public botaoSair: boolean = false;

  public orgaoList: AutocompleteDto[] = new Array<AutocompleteDto>();
  private orgaoUrl = 'orgao';
  public orgaoId = Util.getOrgaoSession();
  public desativarBotaoSairModal: boolean = false;

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private activeModal: NgbActiveModal,
    private autoCompleteService: AutoCompleteService,
    private localStorageService: LocalStorageService,
    private planoService: AcoesDiretrizesService,
    private chartService: ChartService
  ) {
    super(
      router,
      activatedRoute,
    );
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.desativarBotaoSair();
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public createFormGroup() {
    this.form = this.formBuilder.group({
      id: new FormControl(this.pageId),
      nome: new FormControl(''),
      descricao: new FormControl(''),
      orgaoId: new FormControl(null),
    });
  }

  public getAllDashboard(orgaoId?: string): void {
    if (orgaoId) {
      const params = Util.createFilter();
      params.append('orgaoId', orgaoId);
      this.chartService.getDashboard(params).subscribe(result => {
        this.chartService.servicosCadastrados = result.body.data.servicosCadastrados?.pieChartPrestacao;
        this.chartService.servicosPassiveis = result.body.data.servicosPassiveis?.pieChartTransformacao;
        this.chartService.servicosValidados = result.body.data.servicosValidados?.pieChartValiacao;
        window.location.reload();
      });
    }
  }

  onSubmit() {
    let orgaoId = this.form.value.orgaoId.id;

    if (orgaoId != null) {
      this.planoService.getStatusPlanoByOrgaoId(orgaoId)
        .subscribe(res => {
          Util.setStatusPlanoAtivo(res);
        });

      Util.setOrgaoNameSession(orgaoId, this.form.value.orgaoId.descricao);
      this.localStorageService.update();
    }
    else if (this.form.value.orgaoId.descricao === 'TODOS OS ÓRGÃOS') {
      orgaoId = '00000000-0000-0000-0000-000000000000';
      Util.setOrgaoNameSession(orgaoId, this.form.value.orgaoId.descricao);
      this.localStorageService.update();
    }

    this.getAllDashboard(orgaoId);
    this.router.url === '/inicio' ?
    window.location.reload() :
    this.router.navigate(['/inicio']);
    this.closeModal();
  }


  public getAllOrgao() {
    const params = Util.createFilterStatusActive();
    return this.filterAutoSelect(this.orgaoUrl, params);
  }

  private filterAutoSelect(url: string, params: URLSearchParams): void {
    this.autoCompleteService.getForSelect(url, params)
      .subscribe(
        (response) => {
          if (url === this.orgaoUrl) {
            const todosOption: AutocompleteDto = { id: null, descricao: 'TODOS OS ÓRGÃOS' };
            this.orgaoList = [todosOption, ...response.body.data.map(item => new AutocompleteDto(item))];
          }
        }
      );
  }

  public searchOrgao(event) {
    if (event.query) {
      const params = Util.createFilterStatusActive();
      params.append('descricao', event.query.toUpperCase());
      return this.filterAutoSelect(this.orgaoUrl, params);
    }
  }

  public enableSending(): boolean {
    return this.form.get('orgaoId').value !== null;
  }

  desativarBotaoSair() {
    if (this.botaoSair) {
      this.desativarBotaoSairModal = this.botaoSair;
    }
  }

}
