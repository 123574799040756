export class UtilPdf {

    static space() {
        return { text: ' ', style: 'mg' };
    }

    static pageBreak() {
        return { text: '', pageBreak: 'after' };
    }

    static addLinha(param?: string) {
        if (!param || param === 'c') {
            return {
                text: '__________________________________________________________________________________________________',
                style: 'line'
            };
        }

        // linha recuada - indented line
        if (param === 'il') {
            return {
                text: '___________________________________________________________________________________________',
                style: 'indentedLine'
            };
        }

        // linha do rodapé recuada - footer line
        if (param === 'fl') {
            return {
                text: '_____________________________________________________________________________________________________________________________________',
                style: 'footerLine'
            };
        }

        // // escuro - dark
        // if (param === 'd') {
        //     return {
        //         text: '___________________________________________________________________________________________________',
        //         style: 'darkLine'
        //     };
        // }

        // // pontilhada cinza - gray dotted
        // if (param === 'gd') {
        //     return {
        //         style: 'dottedLine',
        //         text: '------------------------------------------------------------------------------------------------------------------------------------------------'
        //     };
        // }

        // // cabeçalho orientação vertical (portrait) - vertical header
        if (param === 'vh') {
            return {
                style: 'headerLine',
                text: '__________________________________________________________________________________________________'
            };
        }

        // // cabeçalho orientação horizontal (landscape) - horizontal header
        // if (param === 'hh') {
        //     return {
        //         style: 'headerLine',
        //         text: '_________________________________________________________________________________________________________________________________________________________'
        //     };
        // }

        // // rodapé horizontal (landscape) - horizontal footer
        // if (param === 'hf') {
        //     return {
        //         text: '_________________________________________________________________________________________________________________________________________________',
        //         style: 'darkLine'
        //     };
        // }
    }

    /**
     * Recebe uma string ("TRUE" ou "FALSE") e retorna Sim ou Não
     */
    static simOuNaoString(param: string): string {
        if (param === 'TRUE' || param === 'true') {
            return 'Sim';
        }

        if (param === 'FALSE' || param === 'false') {
            return 'Não';
        }

        return '';
    }

    static width(param: number) {
        return param * 44.16;
    }

    static offset(param: number) {
        return {
            text: '',
            width: UtilPdf.width(param)
        };
    }

    static row(param: any[]) {
        if (param && param.length > 0) {
            const response = {
                style: 'corpo',
                columns: [],
            };

            param.forEach(item => {
                if (item.offset) {
                    response.columns.push(UtilPdf.offset(item.offset));
                }

                response.columns.push({
                    text: [
                        { text: item.label, bold: true }, item.text, '\n',
                    ], width: UtilPdf.width(item.col)
                });
            });

            return response;
        }
    }

    /**
     * Gera uma lista genérica das opções selecionadas
     */
    static listaCheckedName(param, mensagemVazio?) {
        const lista = [];
        param.forEach(item => {
            if (item.checked) {
                lista.push(item.name + '\n');
            }
        });

        if (mensagemVazio && lista.length === 0) {
            lista.push(mensagemVazio);
            return lista;
        }

        return lista;
    }

    /**
     * Gera uma lista genérica das opções selecionadas
     */
    static listaCheckedValue(param, mensagemVazio?) {
        const lista = [];
        param.forEach(item => {
            if (item.checked) {
                lista.push(item.value + '\n');
            }
        });

        if (mensagemVazio && lista.length === 0) {
            lista.push(mensagemVazio);
            return lista;
        }

        return lista;
    }

    static formatValue(value): string {
        if (!value) {
            if (value !== 0) {
                return '';
            }
        }
        return Number(value).toLocaleString('pt-BR', { style: 'currency', currency: 'BRL' });
    }

}
