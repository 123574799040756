export const RestricaoGenericos = {
  ALL                   : 'ALL',
  CREATE_UPDATE         : 'CREATE_UPDATE',
  CREATE_SUBMETER       : 'CREATE_SUBMETER',
  CRIAR_EDITAR          : 'CRIAR_EDITAR',
  INACTIVATE            : 'INACTIVATE',
  VIEW                  : 'VIEW',
  REMOVE                : 'REMOVE',
  SUBMETER              : 'SUBMETER',
  INVALIDAR             : 'INVALIDAR',
  VALIDAR               : 'VALIDAR',
  AVALIAR               : 'AVALIAR',
  AJUSTE                : 'AJUSTE',
  ACTIVE_INACTIVE       : 'ACTIVE_INACTIVE',
  EDITAR_BLOQUEAR       : 'EDITAR_BLOQUEAR',
  VALIDAR_INVALIDAR     : 'VALIDAR_INVALIDAR',
  DUPLICAR              : 'DUPLICAR',
  CONCLUIDA_ALL         : 'CONCLUIDA_ALL',
  ANDAMENTO_ALL         : 'ANDAMENTO_ALL',
  CONCLUIDA_DETALHAR    : 'CONCLUIDA_DETALHAR'
};
