import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { throwError } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { catchError, map } from 'rxjs/operators';
import { AcaoDiretrizFilterDto } from 'src/app/core/dto/plano/Input/acao-diretriz-filter.dto';
import { AcoesDiretrizesListDto } from 'src/app/core/dto/plano/View/acoes-diretrizes-list.dto';
import { AcoesDiretrizesPlanoCreateDto } from './../../../core/dto/plano/create/acoes-diretrizes-plano-create-dto';
import { ApiService } from './../../../core/services/api.service';

import { SubmeterPlanoInfoDto } from 'src/app/core/dto/plano/submeter-plano/submeter-plano-info.dto';
import { BaseService } from 'src/app/core/services/base.service';
import { urlConfigs } from 'src/app/core/utils/url-configs';
import { PossibilitaEdicaoPlanoDTO } from 'src/app/core/dto/conferencia/possibilita-edicao-plano.dto';

@Injectable({
  providedIn: 'root'
})
export class AcoesDiretrizesService extends BaseService<any> {

  constructor(protected http: HttpClient, protected apiService: ApiService) {
    super(http, `${urlConfigs.url_acao_diretrizes_post_put}`);
  }

  public getAll(filter: AcaoDiretrizFilterDto): Observable<AcoesDiretrizesListDto[]>  {
    return this.apiService.get(`${this.path}?${this.toURLSearchParams(filter).toString()}`)
    .pipe(map((data: any) => data.body.data.map(item => new AcoesDiretrizesListDto(item))));
  }

  public getByFilter(params: URLSearchParams): Observable<AcoesDiretrizesListDto[]> {
    const filters = params ? `?${params.toString()}` : '';
    return this.apiService.get(`${this.path}/${filters}`)
    .pipe(map((data: any) => data.body.data.map(item => new AcoesDiretrizesListDto(item))));
  }

  public duplicate(dto: AcoesDiretrizesPlanoCreateDto): Observable<AcoesDiretrizesListDto[]>  {
    return this.apiService.post(`${this.path}/duplicar-plano`, dto);
  }

  public getAllByOrgaoId(orgaoId: string): Observable<AcoesDiretrizesListDto[]> {
    return this.httpClient
      .get(`${this.baseUrl}${this.path}/orgao/${orgaoId}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)))
      .pipe(map((data: any) => data.body.data.map(item => new AcoesDiretrizesListDto(item))));
  }

  public getServicosByPlano(orgaoId: string): Observable<any>{
    return this.apiService.get(`${urlConfigs.url_search_orgao}/transformacao-digital/servicos/${orgaoId}`)
    .pipe(map(response => (response.body.data)));
  }

  getByIdPlano(id: string) {
    return this.apiService.get(`${this.path}/plano-submeter-ajuste-info/${id}`).pipe(map(response => new SubmeterPlanoInfoDto(response.body.data)));
  }

  public patchSubmeter(id: string) {
    return this.apiService.patch(`${this.path}/submeter-plano/${id}`, id);
  }

  getByIdAvaliar(id: string) {
    return this.apiService.get(`${this.path}/avaliar-info/${id}`).pipe(map(response => new SubmeterPlanoInfoDto(response.body.data)));
  }

  public patchValidar(id: string) {
    return this.apiService.patch(`${this.path}/validar/${id}`, id);
  }

  public updateJustificativa(obj: any) {
    return this.apiService.put(`${this.path}/invalidar/${obj.id}`, obj);
  }

  public ajustarPlanoTransformacaoDigital(obj: any) {
    return this.apiService.put(`${this.path}/ajustar-plano/${obj.id}`, obj);
  }

  inactivate(params: any) {
    return this.apiService.patch(`${this.path}/ativa-inativar`, params);
  }

  getStatusPlanoByOrgaoId(id: string) {
    return this.apiService.get(`${this.path}/plano-ativo-status/${id}`).pipe(map(response => response.body.data));
  }

  patchPossibilitaEdicaoPlano(dto: PossibilitaEdicaoPlanoDTO): Observable<any> {
    return this.apiService.patch(`${this.path}/possibilita-edicao-plano`, dto);
  }

}
