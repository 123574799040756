import { Util } from 'src/app/shared/utils/util';
import { TipoAdministracaoOrgaoEnum } from '../../enums/orgao/tipo-administracao.enum';

export class OrgaoViewModel {
  public id: string;
  public lineOne: string;
  public lineTwo: string;
  public lineThree: string;
  public ativo: boolean;

  constructor(object?: any) {
    if (object) {
      this.id = object.id;
      this.lineOne = object.codigo;
      this.lineTwo = object.nomeAtual;
      this.lineThree = new TipoAdministracaoOrgaoEnum().getName(object.tipoAdministracao);
      this.ativo = object.ativo;
    }
  }
}

