import { Component, Input, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DetalhamentoOrgaopdfDto } from 'src/app/core/dto/graficos/orgao-pdf/detalhamento-orgao-pdf-dto';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { OrgaoDetalhesPdf } from '../orgao-dashboard-detalhes-pdf';
import { Util } from 'src/app/shared/utils/util';
import { FormBase } from 'src/app/shared/utils/form-base';
import { AutoCompleteService } from 'src/app/core/services/auto-complete.service';
import { FormBuilder, FormControl } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AutocompleteDto } from 'src/app/core/dto/auto-complete-dto';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-modal-orgao',
  templateUrl: './modal-orgao.component.html',
  styleUrls: ['./modal-orgao.component.scss']
})
export class ModalOrgaoComponent extends FormBase implements OnInit {

  public orgaos;
  private orgaoUrl: string = 'orgao';
  public orgaoList: AutocompleteDto[] = new Array<AutocompleteDto>();

  constructor(
    private activeModal: NgbActiveModal,
    private chartService: ChartService,
    public activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    private autoComplete: AutoCompleteService,
    public router: Router,
  ) {
    super(router, activatedRoute);
  }

  ngOnInit(): void {
    this.createFormGroup();
    this.getOrgaos();
  }

  public createFormGroup(): void {
    this.form = this.formBuilder.group({
      orgaoId: new FormControl('')
    });
  }

  public getOrgaos(params?: URLSearchParams) {
    this.chartService.getOrgaos().subscribe((response) => {
      this.orgaos = response;
    });
  }

  public closeModal(): void {
    this.activeModal.close();
  }

  public downloadPdf(item) {
    this.chartService.getOrgaoPdf(item.id)
      .toPromise()
      .then(res => {
        OrgaoDetalhesPdf.gerarPdfOrgao(res);
      });
  }

  public submit(): void {
    if (!this.enableShipping()) {
      const filterForm = { ...this.form.value };
      const params = this.getQueryParams(filterForm);

      this.getOrgaos(params);
    }
  }

  public getQueryParams(filterForm): URLSearchParams {
    const params: URLSearchParams = Util.createFilter();

    if (this.form.controls.orgaoId.value) {
      params.append('orgaoId', filterForm.orgaoId.id);
    }

    return params;
  }

  public clearFilter(): void {
    this.form.patchValue({
      nome: '',
      cpf: '',
      perfilId: '',
      orgaoId: ''
    });

    this.getOrgaos();
  }

  public getAllOrgao() {
    const params = Util.createFilterStatusActive();
    return this.filterOrgaoAutoSelect(this.orgaoUrl, params);
  }

  public searchOrgao(event) {
    if (event.query) {
      const params = Util.createFilterStatusActive();
      params.append('descricao', event.query.toUpperCase());
      return this.filterOrgaoAutoSelect(this.orgaoUrl, params);
    }
  }

  private filterOrgaoAutoSelect(url: string, params: URLSearchParams): void {
    this.autoComplete.getForSelect(url, params)
      .pipe(
        map(
          (response) => response.body.data.map(
            item => new AutocompleteDto(item)
          )
        )).subscribe(
          (response) => {
            if (url === this.orgaoUrl) {
              this.orgaoList = response;
            }
          }
        );
  }
}
