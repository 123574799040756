import { EnumBase } from '../../models/enum-base.model';

export class AtivoInativo extends EnumBase {
    constructor() {
        super([
            { value: 'Ativo', name: 'Ativo' },
            { value: 'Inativo', name: 'Inativo' },
        ], 'Selecione o status');
    }
}
