import { EnumBase } from '../../models/enum-base.model';

export class TipoAdministracaoOrgaoEnum extends EnumBase {
    constructor() {
        super(
            [
                { value: 'DIRETA', name: 'Direta' },
                { value: 'INDIRETA', name: 'Indireta' },
            ],
            'Selecione'
        );
    }
}
