import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { NotificacaoListDto } from 'src/app/core/dto/configuracao/notificacao/notificacao-list.dto';
import { NotificacaoOrgaoLFilterDto } from 'src/app/core/dto/configuracao/notificacao/notificacao-orgao-filter.dto';

import { BaseService } from 'src/app/core/services/base.service';
import { urlConfigs } from 'src/app/core/utils/url-configs';

@Injectable({
  providedIn: 'root'
})
export class NotificacaoService extends BaseService<any> {

  constructor(protected http: HttpClient) {
    super(http, `${urlConfigs.url_notificacao}`);
  }

  public getByFilter(filters?: URLSearchParams): Observable<NotificacaoListDto[]> {
    return this.http.get(`${this.baseUrl}${this.path}?${filters ?? ''}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)))
      .pipe(map((data: any) => data.body.data.map(item => new NotificacaoListDto(item))));
  }

  public getByFilterOrgao(): Observable<NotificacaoOrgaoLFilterDto[]> {
    return this.http
      .get(`${this.baseUrl}${urlConfigs.url_notificacao}/Orgaos`, this.HeaderOptions())
      .pipe(
        catchError((e: HttpErrorResponse) => throwError(e)),
        map((data: any) => data.body.data.map((item: any) => new NotificacaoOrgaoLFilterDto(item)))
      );
  }
}
