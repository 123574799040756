<!-- Main Sidebar Container -->
<aside class="main-sidebar sidebar-custom">
  <!-- Para usara caso use style 2  -->
 <!-- <div class="border-rounded top bg-primary-custom br-rb"></div> -->
 <!-- <div class="border-rounded bottom bg-primary-custom br-rt"></div> -->
  <!-- <div class="border-rounded top bg-white br-lt"></div> -->
 <!-- <div class="border-rounded bottom bg-white br-lb"></div -->

 <!-- Sidebar -->
 <div class="sidebar">
   <!-- Sidebar user panel (optional) -->
   <!-- width: 100%;
   height: auto;
   max-width: 300px; -->
   <div class="user-panel">
     <div class="user-info" >
       <img class="cls" style="opacity: .8" src="assets/img/new-logo-pb.png" alt="AdminLTE Logo">
       <img class="opn" src="assets/img/new-logo-pb.png" alt="AdminLTE Logo">
     </div>
   </div>
   <hr>
   <div class="user-panel">
     <div class="info">
       <p class="name-user">{{ usuario }}</p>
       <span class="name-profile"> {{ perfilType }} </span>
     </div>
   </div>

   <!-- Sidebar Menu -->
   <nav class="mt-2">
     <ul class="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu">
       <li class="nav-item" title="Início">
         <a [routerLink]="['/inicio']" class="nav-link">
           <em class="nav-icon fas fa-home"></em>
           <p> INÍCIO </p>
         </a>
       </li>

       <li class="nav-item has-treeview"  *ngIf="hiddenComponenteWithAll(['ORGAO_ALL']) && administradorOrgaoSelecionado()">
         <a   href="javascript:void(0)" class="nav-link">
           <em class="nav-icon fas fa-landmark"></em>
           <p>
             ÓRGÃO
             <em class="right fas fa-angle-left"></em>
           </p>
         </a>
         <ul class="nav nav-treeview">

           <li class="nav-item" *ngxPermissionsExcept="['ORGAO_INFORMACOES_ALL']">
             <a [routerLink]="['/orgao/listar']" class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p> INFORMAÇÕES DO ÓRGÃO </p>
             </a>
           </li>

           <li class="nav-item">
             <a [routerLink]="['/comissao']" class="nav-link" *ngIf="hiddenComponenteWithAll(['ORGAO_COMISSAO_ALL']) && administradorOrgaoSelecionado()">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p> COMISSÃO </p>
             </a>
           </li>

           <li class="nav-item" *ngxPermissionsExcept="['ORGAO_SERVICO_ALL']">
             <a [routerLink]="['/orgao/servicos-orgao/']"  routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>SERVIÇOS</p>
             </a>
           </li>

         </ul>
       </li>

      <ng-container *ngIf="exibirMenu">
       <li class="nav-item has-treeview">
         <a href="javascript:void(0)" class="nav-link">
           <em class="nav-icon fas fa-landmark"></em>
           <p>
             ÓRGÃO
             <em class="right fas fa-angle-left"></em>
           </p>
         </a>
         <ul class="nav nav-treeview">
           <li class="nav-item">
             <a [routerLink]="['/orgao/listar-orgaos']" class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>LISTAR ÓRGÃOS</p>
             </a>
           </li>
         </ul>
       </li>
      </ng-container>

       <li class="nav-item has-treeview" *ngIf="hiddenComponenteWithAll(['TRANSFORMACAO_ALL']) && administradorOrgaoSelecionado()">
         <a  href="javascript:void(0)" class="nav-link">
           <em class="nav-icon fas fa-project-diagram"></em>
           <p>
             TRANSFORMAÇÃO DIGITAL
             <em class="right fas fa-angle-left"></em>
           </p>
         </a>
         <ul class="nav nav-treeview">
           <li class="nav-item" *ngxPermissionsExcept="['PLANO_ALL']">
             <a [routerLink]="['/plano']" routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>PLANO</p>
             </a>
           </li>
         </ul>
       </li>

      <li class="nav-item has-treeview">
        <a  href="javascript:void(0)" class="nav-link" *ngIf="hiddenComponenteWithAll(['CONFERENCIA_ALL'])">
          <em class="nav-icon fas fa-newspaper"></em>
          <p>
            CONFERÊNCIA DE DADOS
            <em class="right fas fa-angle-left"></em>
          </p>
        </a>
        <ul class="nav nav-treeview">
          <li class="nav-item"  *ngIf="hiddenComponenteWithAll(['CONFERENCIA_ANDAMENTO_ALL']) && exibirConferencia() && administradorOrgaoSelecionado()">
            <a [routerLink]="getRotaConferencia()" routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>
                CONFERÊNCIA EM ANDAMENTO <app-led class="ml-1" *ngIf="!usuarioAdm" [status]="getStatus()"></app-led>
              </p>
            </a>
          </li>
          <li class="nav-item">
            <a [routerLink]="['/conferencia/conferencias-concluidas']"  *ngIf="hiddenComponenteWithAll(['CONFERENCIA_CONCLUIDA_ALL'])" routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>CONFERÊNCIAS CONCLUÍDAS</p>
            </a>
          </li>
        </ul>
      </li>

       <li class="nav-item has-treeview" *ngIf="hiddenComponenteWithAll(['NOTIFICACAO_ALL'])">
         <a [routerLink]="['/notificacao']" routerLinkActive="router-link-active"  class="nav-link">
           <em class="nav-icon fas fa-bell"></em>
           <p>
             NOTIFICAÇÕES
           </p>
         </a>
       </li>


       <li class="nav-item has-treeview"  *ngIf="hiddenComponenteWithAll(['CONFIGURACAO_ALL'])">
         <a href="javascript:void(0)" class="nav-link">
           <em class="nav-icon fas fa-cog"></em>
           <p>
             CONFIGURAÇÕES
             <em class="right fas fa-angle-left"></em>
           </p>
         </a>
         <ul class="nav nav-treeview">
           <li class="nav-item" *ngxPermissionsExcept="['USUARIO_ALL']">
             <a [routerLink]="['/configuracao/usuario']" routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>USUÁRIOS</p>
             </a>
           </li>

           <li class="nav-item" *ngIf="hiddenComponenteRestriction(['PERFIL_ALL','PERFIL_ACTIVE_INACTIVE'])">
             <a [routerLink]="['/configuracao/perfil']" routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>PERFIS</p>
             </a>
           </li>

           <li class="nav-item" *ngxPermissionsExcept="['NOTIFICACAO_ALL']">
             <a [routerLink]="['/configuracao/parametrizar-notificacao']"  routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>PARAMET. NOTIFICAÇÃO</p>
             </a>
           </li>

           <li class="nav-item" *ngxPermissionsExcept="['CARGO_ALL']">
             <a [routerLink]="['/configuracao/cargo']"  routerLinkActive="router-link-active"  class="nav-link">
               <em class="nav-icon far fa-circle nav-icon"></em>
               <p>CARGO</p>
             </a>
           </li>

           <li class="nav-item" *ngxPermissionsExcept="['CATEGORIA_ALL']">
            <a [routerLink]="['/configuracao/categoria']"  routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>CATEGORIA</p>
            </a>
          </li>

          <li class="nav-item" *ngxPermissionsExcept="['DOCUMENTACAO_NECESSARIA_ALL']">
            <a [routerLink]="['/configuracao/documentacao-necessaria']"  routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>DOCUMENTAÇÃO</p>
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsExcept="['UNIDADE_FISICA_ALL']">
            <a [routerLink]="['/configuracao/unidade-fisica']"  routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>UNIDADE FÍSICA</p>
            </a>
          </li>
          <li class="nav-item" *ngxPermissionsExcept="['PUBLICO_ALVO_ALL']">
            <a [routerLink]="['/configuracao/publico-alvo']"  routerLinkActive="router-link-active"  class="nav-link">
              <em class="nav-icon far fa-circle nav-icon"></em>
              <p>PÚBLICO ALVO</p>
            </a>
          </li>

         </ul>
       </li>

     </ul>
   </nav>
   <!-- /.sidebar-menu    -->
 </div>
 <!-- /.sidebar -->

 <!-- Brand Logo -->

</aside>
