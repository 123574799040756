import { AcaoDiretrizSubmeterPlanoDto } from "./acao-diretriz-submeter-plano.dto";
import { MembrosComissaoSubmeterPlanoDto } from "./membros-comissao-submeter-plano.dto";
import { AcaoObjetivoSubmeterPlanoDto } from "./objetivos-submeter-plano.dto";

export class SubmeterPlanoInfoDto {
  id: string;
  membrosComissao: MembrosComissaoSubmeterPlanoDto;
  plano: AcaoDiretrizSubmeterPlanoDto;
  objetivos: AcaoObjetivoSubmeterPlanoDto[];

  constructor(object: any) {
    if (object) {
      this.id                 = object.id;
      this.membrosComissao    = object.membrosComissao;
      this.plano              = object.plano;
      this.objetivos          = object.objetivos;
    }
  }
}
