import { Guid } from 'guid-typescript';

export class NotificacaoOrgaoLFilterDto {

  public orgaoId: Guid;
  public orgaoNome: string;

  constructor(object?: any) {
    this.orgaoId                   = object.orgaoId;
    this.orgaoNome                 = object.orgaoNome;
  }
}
