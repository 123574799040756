import { Component, ContentChild, Input, OnChanges, OnInit, SimpleChanges, TemplateRef } from '@angular/core';
import { TableBodyDirective } from './config/table-body.directive';
import { TableEmptyDirective } from './config/table-empty.directive';
import { TableHeaderDirective } from './config/table-header.directive';

@Component({
  selector: 'app-table-custom',
  templateUrl: './table-custom.component.html',
  styleUrls: ['./table-custom.component.scss']
})
export class TableCustomComponent implements OnInit, OnChanges {

  @ContentChild(TableHeaderDirective, { static: true, read: TemplateRef }) appTableHeader;
  @ContentChild(TableBodyDirective, { static: true, read: TemplateRef }) appTableBody;
  @ContentChild(TableEmptyDirective, { static: true, read: TemplateRef }) appTableEmpty;

  @Input() items = new Array();
  @Input() itemsPerPage = 10;
  @Input() paginator = true;
  @Input() classPaginator = 'bcg-navy';
  @Input() messageTotal: string;
  @Input() qtdTotal: number;
  @Input() viewTotal: boolean;

  pageCurrent = 1;

  constructor() { }

  ngOnInit() {
    if (!this.items) {
      this.items = [];
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (typeof changes.items.currentValue !== 'undefined' && changes.items?.currentValue) {
      if (this.items.length <= this.itemsPerPage && this.items.length > 0) {
        this.setCurrentPage(1);
      }
    }
  }

  setCurrentPage(page) {
    this.pageCurrent = page;
  }

}
