export const urlConfigs = Object.freeze({
  url_situacao_cadastral: 'Orgao/situacao-cadastral',
  url_autoComplete: 'Autocomplete',
  url_historico_orgao: 'Orgao/historico',
  url_usuario: 'usuario',
  url_classificacao_acao: 'classificacaoacao',
  url_competencia_orgao: 'Orgao/competencias',
  url_search_orgao: 'Orgao',
  url_programa_acao: 'Orgao/programa-acao',
  url_servico_orgao: 'Orgao/servico',
  url_membros_comissao: 'Orgao/membro-gpegd',
  url_acao_diretrizes_post_put: 'AcaoDiretriz',
  url_etapas: 'Etapa',
  url_cep: 'Cep',
  url_parametrizacao_notificacao: 'ParamsNotificacao',
  url_notificacao: 'Notificacao',
  url_chart: 'dashboard',
  url_get_servicos: 'Orgao/servicos',
  url_dados_cadastrais: 'Orgao/dados-cadastrais',
  url_conferencia_dados: 'Orgao/conferencia-dados',
  url_planos_plurianuais: 'planoPlurianual',
  url_cargo: 'Cargo',
  url_objetivo: 'Objetivo',
  url_acao: 'AcaoObjetivo',
  url_conferencia: 'conferenciaDados',
  url_unidade_fisica: 'unidadeFisica',
  url_publico_alvo: 'publicoAlvo',

});
