import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-template-orgao-modal',
  templateUrl: './template-orgao-modal.component.html',
  styleUrls: ['./template-orgao-modal.component.scss']
})
export class TemplateOrgaoModalComponent {

  // tslint:disable-next-line:no-output-native
  @Output() public close: EventEmitter<any> = new EventEmitter();
  @Input() public title: string;
  @Input() public botaoSair: boolean;

  public closeModal(): void {
    this.close.emit();
  }
}
