import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ConferenciaPdfDto } from 'src/app/core/dto/conferencia/conferencia-pdf.dto';
import { DetalhamentoOrgaopdfDto } from 'src/app/core/dto/graficos/orgao-pdf/detalhamento-orgao-pdf-dto';
import { ServicoDashBoardDetalhamentoDto } from 'src/app/core/dto/graficos/servico-detalhamento-dto';
import { TotalizadorServicosPrestacaoDto } from 'src/app/core/dto/graficos/totalizador-servicos-prestacao.dto';
import { TotalizadorServicosTransformacaoDto } from 'src/app/core/dto/graficos/totalizador-servicos-transformacao.dto';
import { TotalizadorServicosValidacaoDto } from 'src/app/core/dto/graficos/totalizador-servicos-validacao.dto';
import { ApiService } from 'src/app/core/services/api.service';
import { BaseService } from 'src/app/core/services/base.service';
import { urlConfigs } from 'src/app/core/utils/url-configs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ChartService extends BaseService<any> {
  private base = environment.apiUrl;
  public servicosCadastrados: TotalizadorServicosPrestacaoDto[] = [];
  public servicosPassiveis: TotalizadorServicosTransformacaoDto[] = [];
  public servicosValidados: TotalizadorServicosValidacaoDto[] = [];

  constructor(protected http: HttpClient, protected apiService: ApiService) {
    super(http, `${urlConfigs.url_chart}`);
  }

  getDashboard(orgaoId?: URLSearchParams) {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/resumo-orgao-por-status/${filter}`);
  }

  getOrgaos(): Observable<any> {
    return this.apiService.get(`${urlConfigs.url_chart}/Orgaos`)
      .pipe(map(response => response.body.data));
  }

  getServicos(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/Servicos${filter}`)
      .pipe(map(response => response.body.data));
  }

  getServicosOnline(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/VerServicosOnline${filter}`)
      .pipe(map(response => response.body.data));
  }

  getServicosParcialmenteOnline(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/VerServicosParcialmenteOnline${filter}`)
      .pipe(map(response => response.body.data));
  }

  getServicosPresenciais(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/VerServicosPresenciais${filter}`)
      .pipe(map(response => response.body.data));
  }

  getServicosTransformados(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/VerServicosTransformados${filter}`)
      .pipe(map(response => response.body.data));
  }

  getPlanosEmAndamento(orgaoId?: URLSearchParams) {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/OrgaosComPlanoEmAndamento${filter}`)
    .pipe(map(response => response.body.data));
  }

  getServicosPorFormaPrestacao(orgaoId?: URLSearchParams) {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/resumo-orgao-por-status${filter}`)
    .pipe(map(response => response.body.data));
  }

  getOrgaoPdf(orgaoId: string): Observable<DetalhamentoOrgaopdfDto> {
    return this.apiService.get(`${urlConfigs.url_chart}/DetalhamentoOrgaoPDF/${orgaoId}`)
      .pipe(map(response => new DetalhamentoOrgaopdfDto(response.body.data)));
  }

  getDetalharServico(servicoId: string): Observable<ServicoDashBoardDetalhamentoDto> {
    return this.apiService.get(`${urlConfigs.url_chart}/DetalharServico/${servicoId}`)
      .pipe(map(response => new ServicoDashBoardDetalhamentoDto(response.body.data)));
  }

  getOrgaosRanking(orgaoId?: URLSearchParams): Observable<any> {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/OrgaosRankingTransformacao${filter}`)
    .pipe(map(response => response.body.data));
  }

  getServicosTransformacaoDigitalOrgao(orgaoId?: URLSearchParams) {
    const filter = orgaoId ? `?${orgaoId.toString()}` : '';
    return this.apiService.get(`${urlConfigs.url_chart}/OrgaoProgressaoTransformacaoServicos${filter}`)
    .pipe(map(response => response.body.data));
  }

  getServicoUnidadesFisicas(servicoId: string): Observable<any> {
    return this.apiService.get(`${urlConfigs.url_chart}/GetDadosUnidadeFisica/${servicoId}`)
      .pipe(map(response => response.body.data));
  }
}
