<div class="modal-container">
  <div class="modal-header position-relative">
    <div class="close-modal">
      <em class="fas fa-times" (click)="closeModal()"></em>
    </div>
    <h4 class="modal-title" style="font-size: 40px;">Dados do serviço</h4>
  </div>
  <div class="col-12">
    <div class="col mt-3 text-center">
        <h2>
            {{ servico?.orgaoNome }}
        </h2>
    </div>
  </div>
  <hr />
  <div class="modal-body">
    <div class="mt-4">
      <div class="row">
        <div class="col-md-12">
          <div class="title" style="display: inline-block;">
            <strong>Nome do Serviço:</strong>
          </div>
          <div class="content" style="display: inline-block;">
            {{ servico?.nome }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4 mt-2">
          <strong>Público Alvo:</strong>
            <div class="content" *ngFor="let publicoAlvo of servico?.publicoAlvo">
              {{ publicoAlvo }}
            </div>
        </div>
        <div class="col-md-4 mt-2">
          <p><strong>Forma de Prestação:</strong>&nbsp;{{ servico?.formaPrestacao }}</p>
        </div>
        <div class="col-md-4 url" *ngIf="servico?.urls">
          <div class="bg-light p-2 rounded">
            <p><strong>Site/Agendamento:</strong></p>
            <ng-container *ngFor="let url of servico?.urls | slice:0:4 ; index as i">
              <div class="mb-2">
                <div class="content">
                  <a class="link-url" [href]="'https://' + url.acesso" target="_blank">{{ url.acesso }}</a>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="title">
            <strong>Tipo de Taxa:</strong>
          </div>
          <div class="content">
            <strong> {{ servico?.tipoTaxa }}</strong>
              <div class="content" *ngIf="servico?.tipoTaxa === tipoTaxaSobConsulta">
              <a class="link-url" [href]="'https://' + servico?.orgaoUrl" target="_blank">{{ servico?.orgaoUrl }}</a>
              </div>
          </div>
          <div class="content" *ngFor="let item of servico?.taxas">
            {{ item.nome }}: {{ formatValor(item.valor) }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>O que é:</strong>
          </div>
          <div class="content">
            {{ servico?.descricaoServico }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Exigências para realizar o serviço:</strong>
          </div>
          <div class="content">
            {{ servico?.exigencias }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Quanto tempo leva:</strong>
          </div>
          <div class="content">
            {{ servico?.quantoTempoLeva }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Etapas para realização deste serviço:</strong>
          </div>
          <div class="content">
            {{ servico?.etapasProcessamentoServico }}
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Documentação necessária:</strong>
          </div>
          <div class="content" *ngFor="let documentacao of servico?.documentacaoNecessaria">
            {{ documentacao.nome }}
          </div>
        </div>
      </div>
      <div class="row" *ngIf="servico?.unidadeFisicaServico.length > 0">
        <div class="col-md-12">
          <div class="title">
            <strong>Unidade física:</strong>
          </div>
          <ng-container *ngFor="let unidadeFisicaServico of servico?.unidadeFisicaServico | slice:0:4 ; index as i">
            <div class="d-flex">
              <div class="content">
                {{ unidadeFisicaServico.sede }} {{ unidadeFisicaServico.unidades ? ' - ' + unidadeFisicaServico.unidades : '' }}
              </div>
              <button
                id = "detalhar"
                title="Detalhar unidade física"
                class="btn btn-sm mr-2"
                (click)="viewUnidadeFisica(unidadeFisicaServico)"
              >
                <em class="fas fa-search"></em>
              </button>
            </div>

          </ng-container>

          <button
            *ngIf="servico?.unidadeFisicaServico.length > 4"
            id = "detalhar"
            title="Detalhar unidade física"
            class="btn btn-dark rounded-pill float-left"
            (click)="openUnidadesFisicas()"
          >
            Ver mais
          </button>
        </div>
      </div>
      <div *ngIf="servico?.consultaUnidadesFisicasServico" class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Consulta de unidades fisicas de atendimento:</strong>
          </div>
          <div class="content">
            <a [attr.href]="servico?.consultaUnidadesFisicasServico" target="_blank">
              <a class="link-url" [href]="'https://' + servico?.consultaUnidadesFisicasServico" target="_blank">{{ servico?.consultaUnidadesFisicasServico }}</a>
            </a>
          </div>
        </div>
      </div>
      <div *ngIf="servico?.informacaoAdicional" class="row">
        <div class="col-md-12">
          <div class="title">
            <strong>Informações adicionais:</strong>
          </div>
          <div class="content">
            {{ servico?.informacaoAdicional }}
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <div class="title">
          <strong class="custom-strong">Prioridade de Atendimento:</strong>
        </div>
        <div class="content-artigo">
          Art. 1o As pessoas com deficiência, os idosos com idade igual ou superior a 60 (sessenta) anos, as gestantes, as lactantes, as pessoas com crianças de colo e os obesos terão
          atendimento prioritário, nos termos da LEI N° 10.048, DE 8 DE NOVEMBRO DE 2000. Usuários que darão jus à prioridade de atendimento (Redação dada pela Lei n° 13.146, de 2025)
        </div>
      </div>
    </div>
  </div>
  <button class="btn btn-dark rounded-pill float-right mb-3 col-md-2" (click)="closeModal()">
    Fechar
  </button>
  <button class="btn btn-dark rounded-pill float-right mb-3 col-md-4" (click)="openHorarios()">
    Horários de funcionamento
  </button>
</div>
