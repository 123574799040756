import { Component, OnInit } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { Util } from 'src/app/shared/utils/util';
import { ModalViewUnidadeFisicaComponent } from '../modal-view-unidade-fisica/modal-view-unidade-fisica.component';

@Component({
  selector: 'app-modal-unidades-fisicas',
  templateUrl: './modal-unidades-fisicas.component.html',
  styleUrls: ['./modal-unidades-fisicas.component.scss']
})
export class ModalUnidadesFisicasComponent implements OnInit {

  public unidadesFisicas;
  public servicoId: string;
  public orgao;

  constructor(
    private chartService: ChartService,
    private activeModal: NgbActiveModal,
    public modalService: NgbModal
  ) { }

  ngOnInit() {
    this.getUnidadesFisicas(this.servicoId);
  }

  public getUnidadesFisicas(servicoId: string){
    this.chartService.getServicoUnidadesFisicas(servicoId).subscribe((response) => {
      this.unidadesFisicas = response;
    })
  }

  public viewUnidadeFisica(item:any) {
    const modalRef = Util.openModal(this.modalService, ModalViewUnidadeFisicaComponent, 'md');
    modalRef.componentInstance.unidadeFisica = item;
  }

  public closeModal(): void {
    this.activeModal.close();
  }
}
