<app-template-modal [exibirFecharSuperior]="false" [classAdicional]="'btn-dark'">
  <div class="container-fluid" conteudo>
    <div class="col-12">
        <div class="col mt-3 text-center">
            <h1>
                Órgãos
            </h1>
        </div>
    </div>

    <div class="text-center mt-3">
        <h2>Rede PB Digital</h2>
    </div>
    <div class="col-12">
      <app-card-form [title]="'Filtro:'">
        <div form>
          <form [formGroup]="form" (ngSubmit)="submit();" (keydown.enter)="$event.preventDefault()">
            <div class="row">
              <div class="col-12 col-md-12">
                <div class="form-group">
                  <p-autoComplete placeholder="Digite o nome do Órgão..." class="custom-auto-complete custom-auto-complete-radius"
                    formControlName="orgaoId" [delay]="900" dataKey="id" [suggestions]="orgaoList"
                    [forceSelection]="true" (completeMethod)="searchOrgao($event)" field="descricao"
                    [dropdown]="true" (onDropdownClick)="getAllOrgao()" [emptyMessage]="'Nenhum resultado encontrado'"
                    minLength="1" [ngClass]="setErrorValidate('orgaoId')" (focusout)="updateErrors();">
                  </p-autoComplete>
                </div>
              </div>
            </div>
            <div class="container-fluid">
              <div class="row justify-content-end">
                <button type="button" class="btn btn-warning-light rounded-pill mb-2 col-12 col-sm-6 col-md-auto"
                  (click)="clearFilter();">
                  <i class="fa fa-eraser"></i>
                  Limpar
                </button>
                <button type="submit" class="btn btn-dark rounded-pill mb-2 col-12 col-sm-6 col-md-auto ml-md-2"
                  (click)="submit();" [disabled]="enableShipping()">
                  <i class="fa fa-search"></i>
                  Buscar
                </button>
              </div>
            </div>
          </form>
        </div>
      </app-card-form>
    </div>

    <div id="listagem" class="row scroll-div mb-4">
        <div class="col-md-12">
          <div class="col-12 col-md-12">
            <app-table-custom [items]="orgaos" [itemsPerPage]="10">
              <ng-template appTableHeader>
                <tr class="th-color">
                  <th id="orgao">ÓRGÃO</th>
                  <th id="detalhar">DETALHAR ÓRGÃO</th>
                </tr>
              </ng-template>
              <ng-template appTableBody let-item>
                <tr>
                  <td class="text-capitalize">
                    {{ item.nome }}
                  </td>
                  <td>
                    <button
                      title="Detalhar órgão"
                      class="btn btn-sm mr-2">
                      <em class="fas fa-search" (click)="downloadPdf(item)"></em>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template appTableEmpty>
                <tr>
                  <td colspan="2" class="text-center">
                    Nenhum órgão cadastrado
                  </td>
                </tr>
              </ng-template>
            </app-table-custom>
          </div>
        </div>
    </div>
    <button class="btn btn-dark rounded-pill float-right mb-3 col-md-2" (click)="closeModal()">
        Fechar
    </button>
  </div>
</app-template-modal>
