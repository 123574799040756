import { Directive } from '@angular/core';

@Directive({
  selector: '[appTableEmpty]'
})
export class TableEmptyDirective {

  constructor() { }

}
