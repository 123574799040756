import { Routes } from '@angular/router';
import { LayoutAuthComponent } from './core/layouts/layout-auth/layout-auth.component';
import { LayoutNoAuthComponent } from './core/layouts/layout-no-auth/layout-no-auth.component';
import { LandingPageComponent } from './modules/landing-page/pages/landing-page/landing-page.component';

export const APP_ROUTES: Routes = [
  {
    path: '',
    redirectTo: 'transparencia',
    pathMatch: 'full'
  },
  {
    path: 'transparencia',
    component: LandingPageComponent,
    loadChildren: () =>
      import('./modules/landing-page/landing-page.module').then((m) => m.LandingPageModule),
  },
  {
    path: 'auth',
    component: LayoutNoAuthComponent,
    loadChildren: () =>
      import('./modules/auth/auth.module').then((m) => m.AuthModule),
  },
  {
    path: '',
    component: LayoutAuthComponent,
    children: [
      {
        path: 'inicio',
        loadChildren: () =>
          import('./modules/home/home.module').then((m) => m.HomeModule),
      },
      {
        path: 'configuracao',
        loadChildren: () =>
          import('./modules/configuracao/configuracao.module').then(
            (m) => m.ConfiguracaoModule
          ),
      },
      {
        path: 'orgao',
        loadChildren: () =>
          import('./modules/orgao/orgao.module').then((m) => m.OrgaoModule),
      },
      {
        path: 'plano',
        loadChildren: () =>
          import('./modules/plano/plano.module').then((m) => m.PlanoModule),
      },
      {
        path: 'objetivo',
        loadChildren: () =>
          import('./modules/objetivo/objetivo.module').then((m) => m.ObjetivoModule),
      },
      {
        path: 'comissao',
        loadChildren: () =>
          import('./modules/comissao/comissao.module').then((m) => m.ComissaoModule),
      },
      {
        path: 'notificacao',
        loadChildren: () =>
          import('./modules/notificacao/notificacao.module').then(
            (m) => m.NotificacaoModule
          ),
      },
      {
        path: 'conferencia',
        loadChildren: () =>
          import('./modules/conferencia/conferencia.module').then(
            (m) => m.ConferenciaModule
          ),
      },
      {
        path: 'orgao',
        loadChildren: () =>
          import('./modules/orgao-listar-todos/orgao-listar-todos.module').then(
            (m) => m.OrgaoListarTodosModule
          ),
      }
    ],
  },
];
