import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ConferenciaValidacaoServicoDto } from 'src/app/core/dto/conferencia/conferencia-validacao-servico.dto';
import { ServicoOrgaoListDto } from 'src/app/core/dto/orgao/view/servico-orgao-list-dto';
import { ServicosInfoDto } from 'src/app/core/dto/orgao/view/servicos-info-dto';
import { SubmeterModalCreateDto } from 'src/app/core/dto/plano/create/submeter-modal-create-dto';


import { BaseService } from 'src/app/core/services/base.service';
import { urlConfigs } from 'src/app/core/utils/url-configs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ServicoOrgaoService extends BaseService<any>{
  private baseUrl2 = environment.apiUrl;

  constructor(protected http: HttpClient) {
    super(http, `${urlConfigs.url_servico_orgao}`);
  }

  public putSituacaoCadastral(body: any): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl2}${urlConfigs.url_servico_orgao}`, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public getAllById(id: string): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}${urlConfigs.url_get_servicos}/${id}`, this.HeaderOptions());
  }

  public getByFilter(params?: URLSearchParams) {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpClient
      .get(`${this.baseUrl}${urlConfigs.url_get_servicos}/${filters}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)))
      .pipe(map((data: any) => data.body.data.map(item => new ServicosInfoDto(item))));
  }

  public create(item: any): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}${urlConfigs.url_servico_orgao}/criar`, item, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public update(item: any): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}${urlConfigs.url_servico_orgao}/editar`, item, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public submeterServicoValidacaoCreate(dto: SubmeterModalCreateDto) {
    return this.httpClient
      .post(`${this.baseUrl}${urlConfigs.url_servico_orgao}/enviar-servico-validacao`, dto, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public postServicoValidacaoCreate(dto: ConferenciaValidacaoServicoDto) {
    return this.httpClient
      .post(`${this.baseUrl}${urlConfigs.url_servico_orgao}/validar-servico`, dto, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}

