import { HttpClient, HttpErrorResponse, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  private baseUrl = environment.apiUrl;
  private namespaceProject = environment.namespaceProject;
  constructor(
    private httpClient: HttpClient
  ) { }

  HeaderOptions(): any {
    // tratar caso tenha o token
    const TOKEN = localStorage.getItem(`${this.namespaceProject}.token`) ? 'Bearer ' + localStorage.getItem(`${this.namespaceProject}.token`) : '';

    return {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
         Authorization: TOKEN,
      }),
      observe: 'response' as 'response'
    };
  }

  /**
   * Generic GET Method
   * @param path URL path
   */
  public get(path: string): Observable<any> {
    return this.httpClient
               .get(this.baseUrl + path, this.HeaderOptions())
               .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic POST Method
   * @param path URL path
   * @param body Request body
   */
  public post(path: string, body: any): Observable<any> {
    return this.httpClient
               .post(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
               .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PUT Method
   * @param path URL path
   * @param body Request body
   */
  public put(path: string, body: any): Observable<any> {
    return this.httpClient
               .put(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
               .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic PATCH Method
   * @param path URL path
   * @param body Request body
   */
  public patch(path: string, body: any): Observable<any> {
    return this.httpClient
               .patch(this.baseUrl + path, JSON.stringify(body), this.HeaderOptions())
               .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  /**
   * Generic DELETE Method
   * @param path URL path
   */
  public delete(path: string): Observable<any> {
    return this.httpClient
               .delete(this.baseUrl + path, this.HeaderOptions())
               .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

}
