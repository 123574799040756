import { Component, OnInit } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ChartService } from 'src/app/modules/home/services/chart.service';
import { Util } from 'src/app/shared/utils/util';

import { ModalOrgaoComponent } from './modal-orgao/modal-orgao.component';
import { ModalServicoComponent } from './modal-servico/modal-servico.component';
import { ModalOrgaoPlanoTransformacaoComponent } from './modal-orgao-plano-transformacao/modal-orgao-plano-transformacao.component';
import { ActivatedRoute, Router } from '@angular/router';

declare var $: any;
@Component({
  selector: 'app-landing-page',
  templateUrl: './landing-page.component.html',
  styleUrls: ['./landing-page.component.scss']
})
export class LandingPageComponent implements OnInit {

  public orgaos;
  public qtdOrgaos;
  public servicos;
  public qtdServicos;
  public qtdOrgaosPlanoEmAndamento;
  public percentOrgaosPlanoEmAndamento;
  public percentPlanosTransformacaoDigital;

  public percentOnline;
  public qtdServicosOnline;
  public qtdServicosParcialOnline;
  public percentParcialOnline;
  public percentPresencial;
  public percentTransformados;
  public qtdServicosPresencial;
  public qtdServicosTransformados;
  public formaPrestacao;
  public orgaosComPercentual;
  public orgaosRanking;
  public orgaosRankingResumido;
  public selectedItem;

  constructor(
    private chartService: ChartService,
    public modalService: NgbModal,
    public activatedRoute: ActivatedRoute,
    public router: Router
  ) { }


  ngOnInit() {
    this.toggleSidebar();
    this.getOrgaos();
    this.getServicos();
    this.getServicosPorFormaPrestacao();
    this.getOrgaosComPlanoEmAndamento();
    this.getOrgaosRanking();
    if (this.router.url === '/transparencia') {
      this.redirectVisaoGeral();
    }
  }

  public getOrgaos() {
    this.chartService.getOrgaos().subscribe((response) => {
      this.orgaos = response;
      this.countOrgaos(response);
      this.getQtdOrgaosPlanoEmAndamento(response);
    });
  }

  public getServicos() {
    this.chartService.getServicos().subscribe((response) => {
      this.servicos = response;
      this.countServicos(response);
      this.getPercentPlanosTransformacao(response);
    });
  }

  public getServicosPorFormaPrestacao() {
    this.chartService.getServicosPorFormaPrestacao().subscribe((resp) => {
      this.percentOnline = resp.servicosOnline.porcentagem;
      this.percentParcialOnline = resp.servicosParciamenteOnline.porcentagem;
      this.percentPresencial = resp.servicosPresenciais.porcentagem;
      this.percentTransformados = resp.servicosTransformados.porcentagem;
      this.qtdServicosOnline = resp.servicosOnline.quantidade;
      this.qtdServicosParcialOnline = resp.servicosParciamenteOnline.quantidade;
      this.qtdServicosPresencial = resp.servicosPresenciais.quantidade;
      this.qtdServicosTransformados = resp.servicosTransformados.quantidade;
    });
  }

  public getQtdOrgaosPlanoEmAndamento(orgaos) {
    this.qtdOrgaosPlanoEmAndamento = orgaos.filter(x => x.planoEmAndamento).length;
    this.getPercentOrgaosPlanoEmAndamento();
  }

  public getPercentOrgaosPlanoEmAndamento() {
    this.percentOrgaosPlanoEmAndamento = Math.ceil(((this.qtdOrgaosPlanoEmAndamento / this.qtdOrgaos) * 100));
  }

  public getPercentPlanosTransformacao(servico) {
    const servicosOnline = servico.filter(x => x.formaPrestacao === 'Online');
    const qtdServicosOnlineValidados = servicosOnline.filter(y => y.validado).length;
    this.percentPlanosTransformacaoDigital = ((this.qtdServicos !== 0 ? qtdServicosOnlineValidados / this.qtdServicos : 0) * 100).toFixed(2);
  }

  public countOrgaos(orgaos) {
    this.qtdOrgaos = orgaos.length;
  }

  public countServicos(servicos) {
    this.qtdServicos = servicos.length;
  }

  public toggleSidebar() {
    $('#menu-toggle').on('click', (e) => {
      e.preventDefault();
      $('#page-content-wrapper').toggleClass('toggled');
    });
  }

  public setActiveClass(item: any) {
    this.selectedItem = item;
  }

  public redirectVisaoGeral() {
    this.router.navigate([ 'transparencia/visao-geral' ]);
    const visao = document.getElementById('visaoGeral');
    visao.classList.add('active');
    const orgao = document.getElementById('visaoOrgao');
    orgao?.classList.remove('active');
  }

  public redirectOrgao(orgaoId: string) {
    this.router.navigate([ 'transparencia/visao-geral' ]).then(() => {
      this.router.navigate([ 'transparencia/orgao', orgaoId]);
    });
    const visao = document.getElementById('visaoGeral');
    visao.classList.remove('active');
  }

  public getOrgaosComPlanoEmAndamento() {
    this.chartService.getPlanosEmAndamento().subscribe((response) => {
      this.orgaosComPercentual = response.slice(0, 5);
    });
  }

  public getOrgaosRanking() {
    this.chartService.getOrgaosRanking().subscribe((response) => {
      this.orgaosRanking = response.slice(0, 5);
    });
  }

  public openModalOrgao() {
    const modalRef = Util.openModal(this.modalService, ModalOrgaoComponent, 'lg');
  }

  public openModalServico(formaPrestacao?: any) {
    const modalRef = Util.openModal(this.modalService, ModalServicoComponent, 'lg');
    modalRef.componentInstance.formaPrestacao = formaPrestacao;
  }

  public openModalOrgaoPlanoAndamento() {
    const modalRef = Util.openModal(this.modalService, ModalOrgaoPlanoTransformacaoComponent, 'lg');
    modalRef.componentInstance.orgaos = this.orgaosComPercentual;
  }

  public redirectLogin() {
    this.router.navigate([ 'auth' ]);
  }
}
