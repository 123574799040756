import { EnumBase } from '../../models/enum-base.model';

export class DiasSemana extends EnumBase {
  constructor() {
    super([
      { value: 'SEGUNDA', name: 'Segunda-feira' },
      { value: 'TERCA', name: 'Terça-feira' },
      { value: 'QUARTA', name: 'Quarta-feira' },
      { value: 'QUINTA', name: 'Quinta-feira' },
      { value: 'SEXTA', name: 'Sexta-feira' },
      { value: 'SABADO', name: 'Sábado' },
      { value: 'DOMINGO', name: 'Domingo' },
    ]);
  }
}
