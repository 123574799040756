export enum NotificacoesEnum {
  PLANOS_FINALIZADO = 'd19c0d66-7f1d-44b0-a70d-b4d5f0dbb0bf',
  ORGAO_FINALIZADO = '49d8711a-bb3b-42ea-8d9f-549ee0678e99',
  ENVIO_CONFERENCIA_PLANO = '3ccf1d64-b8ff-4b16-b21e-c8c280e4811f',
  ENVIO_SUBMISSAO_SERVICO = '3eef4eaa-f55e-41ae-992a-2c7cd823a913',
  FINALIZACAO_TRANSFORMACAO_DIGITAL = 'b01e8a2f-61fe-4493-bbbf-919bf8e8f058',
  APROVACAO_TRANSFORMACAO_DIGITAL = '64bb2025-d8b4-442f-9c13-45f2499b8f3a',
  REPROVACAO_TRANSFORMACAO_DIGITAL = '29c76cc6-0e4f-4e53-b0ed-7b276c85f6bd',
  VALIDACAO_SERVICO = '17bd1987-06e2-4f8b-8f76-efd7d3853d22',
  INVALIDACAO_SERVICO = '9ce2ff0c-0966-4289-b43e-84be8b6fbbad',
  SERVICO_DESVINCULADO = 'dfd7b379-5655-446f-9058-89fc1941980a',
}
