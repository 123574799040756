
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from '@angular/common/http';
import { Inject } from '@angular/core';
import jwt_decode from 'jwt-decode';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';

import { environment } from 'src/environments/environment';
import { urlConfigs } from '../utils/url-configs';
import { SweetalertCustom } from 'src/app/shared/utils/sweetalert-custom';
import { MSG_ADD_SUCESS, MSG_TIME_OUT } from 'src/app/shared/utils/constants';

export class BaseService<T> {
  protected baseUrl = environment.apiUrl;
  protected namespaceProject = environment.namespaceProject;

  constructor(protected httpClient: HttpClient, @Inject(String) protected path) {}

  public HeaderOptions(): any {
    const token = localStorage.getItem(`${this.namespaceProject}.token`);
    let headers = new HttpHeaders({
      'Content-Type': 'application/json',
    });

    if (token) {

      const tokenExpiration = this.getTokenExpiration(token);
      const currentTimestamp = Math.floor(Date.now() / 1000);
      if (tokenExpiration && tokenExpiration < currentTimestamp) {

        this.showTokenExpirationModal();
      } else {

        headers = headers.set('Authorization', 'Bearer ' + token);
      }
    }

    return {
      headers: headers,
      observe: 'response' as 'response',
    };
  }

  private getTokenExpiration(token: string): number | null {
    const decodedToken: any = jwt_decode(token);
    if (decodedToken && decodedToken.exp) {
      return decodedToken.exp;
    }
    return null;
  }

  private showTokenExpirationModal() {
    SweetalertCustom.showAlertConfirm2('warning', MSG_TIME_OUT).then(() => {
      window.location.href = '/auth/logout';
    });
  }

  public getById(id: string): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}${this.path}/${id}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public get(): Observable<any> {
    return this.httpClient
      .get(`${this.baseUrl}${this.path}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public post(body: T): Observable<any> {
    return this.httpClient
      .post(
        `${this.baseUrl}${this.path}`,
        JSON.stringify(body),
        this.HeaderOptions()
      )
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public postId(body: T, id: string): Observable<any> {
    return this.httpClient
      .post(
        `${this.baseUrl}${this.path}/${id}`,
        JSON.stringify(body),
        this.HeaderOptions()
      )
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public put(id: string, body: T): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}${this.path}/${id}`, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public putBody(body: T): Observable<any> {
    return this.httpClient
      .put(`${this.baseUrl}${this.path}`, JSON.stringify(body), this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public patch(body: T): Observable<any> {
    return this.httpClient
      .patch(
        `${this.baseUrl}${this.path}`,
        JSON.stringify(body),
        this.HeaderOptions()
      )
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public delete(id: string): Observable<any> {
    return this.httpClient
      .delete(`${this.baseUrl}${this.path}/${id}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }

  public getForSelect(url: string, params?: URLSearchParams): Observable<any> {
    const filters = params ? `?${params.toString()}` : '';
    return this.httpClient.get(`${this.baseUrl}${this.path}/${url}${filters}`, this.HeaderOptions());
  }

  public toURLSearchParams(obj: any): URLSearchParams {
    const params = new URLSearchParams();
    Object.keys(obj).forEach(key => {
      if (typeof obj[key] === 'boolean' || typeof obj[key] === 'number' || obj[key]) {
        params.set(key, obj[key]);
      }
    });
    return params;
  }

}
