import { Component, DoCheck, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-paginator-custom',
  templateUrl: './paginator-custom.component.html',
  styleUrls: ['./paginator-custom.component.scss'],
})
export class PaginatorCustomComponent implements DoCheck, OnInit {
  @Input() items: Array<any>;
  @Input() itemsPerPage: number = 0;
  @Input() stylesClass: '';
  @Input() msgTotalRegistros: string = 'Total de registros';
  @Output() pageChange: EventEmitter<number> = new EventEmitter();
  @Input() messageTotal: string;
  @Input() qtdTotal: number;
  @Input() viewTotal: boolean;
  public size: number = 0;
  public end: number = 0;

  ngOnInit() {
  }

  ngDoCheck(): void {
    if (this.items.length > 0) {
      this.size = Math.floor(this.items.length / this.itemsPerPage);
    }
  }

  public getTotalPerPage(page) {
    const start = (page.getCurrent() - 1) * this.itemsPerPage + 1;
    this.end = Math.min(start + this.itemsPerPage - 1, page.getTotalItems());

    if (isNaN(this.end)) {
     this.end = 0;
    }

    if (this.items.length === 0) {
      this.end = 0;
    }

    return this.end ? this.end : '0';
  }
}
