<div class="d-flex" id="wrapper">
  <!-- Sidebar -->
  <div class="border-right" id="sidebar-wrapper">
    <div class="sidebar-heading">
      <div class="logoTop">
        <img class="logoMini" src="assets/img/GovPBT.png" height="100%" width="200px" alt="AdminLTE Logo">
        <h3 class="mt-3 mb-1"><b>Rede PB Digital</b></h3>
      </div>
    </div>
    <div class="list-group list-group-flush" (scroll)="true">
      <a (click)="redirectVisaoGeral()" id="visaoGeral" class="list-group-item list-group-item-action active">
        <p class="link">VISÃO GERAL</p>
      </a>
      <a (click)="redirectOrgao(orgao.id)" id="visaoOrgao" [class.active]="orgao === selectedItem" (click)="setActiveClass(orgao)" class="list-group-item list-group-item-action" *ngFor="let orgao of orgaos">
        <p class="link">{{ orgao.nome }}</p>
      </a>
    </div>
    <div class="logoFooter">
      <img class="logoPB" height="100%" width="130px" src="assets/img/new-logo-pb.png" alt="AdminLTE Logo">
    </div>
  </div>
  <!-- /#sidebar-wrapper -->
  <!-- Page Content -->
  <div id="page-content-wrapper">
    <nav class="navbar navbar-expand-lg">
      <button class="btn btn-default" id="menu-toggle">
        <em class="fas fa-bars"></em>
      </button>
      <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent"
        aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse" id="navbarSupportedContent">
        <div class="title-nav">
          <h4 class="text-center"><strong>Dashboard da Transformação Digital</strong></h4>
        </div>
        <a href="javascript:void(0)">
          <button type="submit" class="btn btn-dark button-action btn-block" (click)="redirectLogin()">
            Entre no sistema
            <em class="fas fa-user"></em>
          </button>
        </a>
      </div>
    </nav>
    <div class="d-flex col-12 p-0">
      <div class="col bg-danger" style="height: 4px;"></div>
      <div class="col bg-warning" style="height: 4px;"></div>
      <div class="col bg-success" style="height: 4px;"></div>
      <div class="col bg-primary" style="height: 4px;"></div>
    </div>
    <router-outlet></router-outlet>
  </div>
</div>
