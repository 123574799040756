<div class="content-login">
    <img alt="Logo" class="figureGeometric triangle" width="100" src="assets/img/polygono.png">
    <img alt="Logo" class="figureGeometric circle-yellow" src="assets/img/elipse-amarelo.png">
    <img alt="Logo" class="figureGeometric circle" src="assets/img/elipse-verde.png">
  <div class="login-box">
    <img alt="Logo" class="brand-image mb-3" width="100" height="100" src="assets/img/Brasao_paraiba.png">
    <div class="title-card">
      <h1>PB - DIGITAL</h1>
    </div>
    <div class="mt-3 text-left" style="width: 300px;">
      <router-outlet></router-outlet>
    </div>
  </div>
   <small class="mt-3">Secretaria de Estado da Administração</small>
  <small class="text-center">{{environment.version}}v</small>
</div>

