import { Directive } from '@angular/core';

@Directive({
  selector: '[appTableBody]'
})
export class TableBodyDirective {

  constructor() { }

}
