import { FormArray, FormControl, FormGroup } from '@angular/forms';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment';
import { SetColorInterface } from 'src/app/core/interfaces/set-colo-interface';

import { ErrorMessage } from 'src/app/core/models/error-message.model';
import { environment } from 'src/environments/environment';
import { TipoPerfil } from 'src/app/core/enums/tipo-perfil.enum';
import { Status } from 'src/app/core/enums/plano/status.enum';

const NAME_PROJECT  = environment.namespaceProject;

export class Util {

  static formatDateHours(date?: string): string {
    if (date) {
      const ano = date.substring(0, 4);
      const mes = date.substring(5, 7);
      const dia = date.substring(8, 10);

      const newDate = new Date(date);

      const horas = newDate.getHours();
      const minutos = newDate.getMinutes();

      return dia + '/' + mes + '/' + ano + ' ás ' + horas + ':' + minutos;
    }
    return '';
  }
  /**
   * Função para setar os errors da requisição conforme de acordo com os seus tipos
   * @param errorMessage Objeto utilizado para incluir os erros
   * @param error Objeto que vem o HttpErroResponse
   * @param typeErr String para setar qual o tipo de error ex: danger, success, warning, info
   * @param errorCustom Boolean para caso queira inserir uma mensagem sem ser pelo tipo do erro da requisição
   */
  static setErrorMessage(
    errorMessage: ErrorMessage,
    error,
    typeErr = 'danger',
    errorCustom = false
  ) {
    if (errorCustom) {
      errorMessage.errorType  = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList  = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 400) {
      errorMessage.errorType  = typeErr;
      errorMessage.existError = true;
      errorMessage.errorList  = this.setErrorList(error);
    }

    if (!errorCustom && error.status === 403) {
      errorMessage.errorType  = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Usuário não tem permissão de acesso!');
    }

    if (!errorCustom && error.status === 0 || error.status === 404) {
      errorMessage.errorType  = 'warning';
      errorMessage.existError = true;
      errorMessage.errorList.push('Oopss ocorreu um erro ao processar o seu pedido, não conseguimos conectar com os nossos serviços, por favor tente novamente!');
    }

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, 10000);
  }

  static setErrorMessageCustom(errorMessage: ErrorMessage, error: any, typeErr = 'danger', timeoutClearMessage = 30000) {

    errorMessage.errorType  = typeErr;
    errorMessage.existError = true;
    errorMessage.errorList  = this.setErrorListCustom(error);

    setTimeout(() => {
      this.clearErrorMessage(errorMessage);
    }, timeoutClearMessage);
  }

  /**
   * Função para Limpar Objeto utilizado para incluir os erros
   * @param errorMessage Objeto utilizado para incluir os erros
   */
  static clearErrorMessage(errorMessage: ErrorMessage) {
    errorMessage.errorType  = '';
    errorMessage.existError = false;
    errorMessage.errorList  = [];
  }

  /**
   * Função utilizada para setar os errors na lista de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorList(error) {
    let list = [];
    Array.isArray(error.error.errors) ? list = error.error.errors : list.push(error.error.errors);
    return list;
  }

  /**
   * Função utilizada para setar os errors na lista custumizada de acordo com a requisição
   * @param error Objeto que vem o HttpErroResponse
   */
  static setErrorListCustom(error) {
    let list = [];
    Array.isArray(error) ? list = error : list.push(error);
    return list;
  }

  /**
   * Função para alterar o valor do botão de Buscar
   * @param buttonSubmitConfig Objeto para configuração do botão
   * @param isResp Controle a submissão do botão
   */
  static setBtnFilterReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Buscando' : 'Buscar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReq(buttonSubmitConfig?, isResp = false) {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? 'Salvando' : 'Salvar';
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }

  /**
   * Função para alterar o valor do botão de Submissão
   * @param buttonSubmitConfig Objeto para configuração do submissão
   * * @param isResp Controle a submissão do botão
   */
  static setBtnSubmitReqCustom(buttonSubmitConfig?, isResp = false, desc1 = '', desc2 = '') {
    if (buttonSubmitConfig) {
      buttonSubmitConfig.buttonText = !isResp ? desc2 : desc1;
      buttonSubmitConfig.buttonSubmited = !isResp;
    }
  }
  /**
   * Metodo para pegar o nome para a tela (cadastrar/editar)
   * @param id Identificador
   * @param detalhes Bolean para informar se a tela é de detalhes
   */
  static getScreenName(id?: string, detalhes = false) {
      return (!id || !id.trim()) ? 'ADICIONAR' : (id && !detalhes) ? 'EDITAR' : 'VISUALIZAR';
  }

  /**
   * Função para setar a classe de errro no campo
   * @param formGroup FormGroup do parametro
   * @param messageDisplay Mensagem a ser exibida
   * @param field Campo que receberá a mensagem de validação
   */
  static setErrorsValidate(formGroup: FormGroup, messageDisplay, field: string) {
    if (messageDisplay[field]) {
      return 'is-invalid';
    }
  }

  /**
   * Função para preencher os valores pelo ID
   * @param resp variavel que vem os dados da requisição
   * @param formGroup variavel que traz o form group
   */
  static patchValueForm(obj: any, formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(key => {
      if (obj[key]) {
        formGroup.controls[key].patchValue(obj[key]);
      }
    });
  }

  static objectToFile(obj){
    return new File([obj.binary], obj.nomeOriginal ? obj.nomeOriginal : obj.nome, {type: 'application/pdf', lastModified: obj.lastModify});
  }

  /**
   * Adiciona na URL o parametro de 'Ativo' com o valor 'true'
   */
  static createFilterStatusActive() {
    const params: URLSearchParams = new URLSearchParams();
    params.append('ativo', 'true');

    return params;
  }
  /**
   * Formatar datas
   */
  static convertDate(obj: string): string {
    obj = (moment(obj)).format('YYYY-MM-DD');
    return obj;
  }

  static convertDateCustom(obj: string): string {
    obj = (moment(obj)).format('DD/MM/YYYY');
    return obj;
  }

  /**
   * Retorna as URL Search Params utilizadas nas requisições
   */
  static createFilter() {
    const params: URLSearchParams = new URLSearchParams();
    return params;
  }

  /**
   * Remove os acentos da string
   * @param str string para ser removida os acentos
   */
  static removeAccents(str) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }

  /**
   * Limpa todos os campo do form
   * @param formGroup O form a ser limpo os campos
   */
  static clearFields(formGroup: FormGroup | FormArray) {
    Object.keys(formGroup.controls).forEach(campo => {
      const control = formGroup.get(campo);
      if (control instanceof FormGroup || control instanceof FormControl) {
        if (control instanceof FormGroup) {
          this.clearFields(control);
        } else {
          if (Array.isArray(control.value) ) {
            control.setValue([]);
          } else {
            control.setValue('');
          }
        }
      } else if (control instanceof FormArray) {
        control.controls.splice(0);
        control.updateValueAndValidity();
      }
    });
    formGroup.updateValueAndValidity();
  }

  /**
   * Cria as query params do filtro de busca
   * @param param Objero a ser convertido em parametros
   */
  static getQueryParams(param: object) {
    const params: URLSearchParams = Util.createFilter();

    Object.keys(param).forEach(campo => {
        const item = param[campo];
        if (item) {
            params.append(campo, item);
        }
    });

    return params;
  }

  /**
   * Obtem os campos invalidos do form
   * @param form Form a ser verificado
   */
  static catchFieldsInvalids(form: FormGroup | FormArray): string[] {
    const invalidControls: string[] = [];

    const recursiveFunc = (formGP: FormGroup | FormArray) => {
        Object.keys(formGP.controls).forEach(field => {
            const control = formGP.get(field);
            if (control instanceof FormGroup) {
                recursiveFunc(control);
            } else if (control instanceof FormArray) {
                recursiveFunc(control);
            } else {
                if (control.invalid) { invalidControls.push(field); }
            }
        });
    };
    recursiveFunc(form);
    return invalidControls;
  }

  /**
   * * Abre o modal no tamanho
   * @param modalService Serviço do modal
   * @param component O componente a ser aberto
   * @param size O tamanho do modal (lg, xl, sm) / Valor defaul md
   */
  static openModal(modalService: NgbModal, component: any, size: string = 'md') {
    const modalRef = modalService.open(component,
        { backdrop: 'static', size, keyboard: false, windowClass: 'modal-custom-' + size }
    );

    return modalRef;
  }

  /**
   * Convert o arquivo de base64 em BlobData
   * @param base64Data Arquivo em Base64
   * @param contentType Tipo do content type
   * @param sliceSize Tamanhp do arquivo
   */
  static convertBase64ToBlobData(base64Data: string, contentType: string = 'image/png', sliceSize = 512) {
    const byteCharacters = atob(base64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
            byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
    }

    return new Blob(byteArrays, { type: contentType });
  }

  /**
   * Realiza o download do arquivo
   * @param file Arquivo para ser baixado
   */
  static downloadFile(file: any) {
    const blobData = Util.convertBase64ToBlobData(file.base64);

    if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blobData, file.filename);
    } else { // chrome
        const blob = new Blob([blobData], { type: file.extension });
        const url = URL.createObjectURL(blob);
        const ext = file.extension.split('/').length > 0 ? file.extension.split('/')[1] : '';
        if (ext === 'pdf' || ext === 'PDF' ||
            ext === 'png' || ext === 'PNG' ||
            ext === 'jpg' || ext === 'JPG' ||
            ext === 'jpeg' || ext === 'JPEG') {
            window.open(url, '_blank');
            return;
        }

        const link = document.createElement('a');
        link.href = url;
        link.download = file.filename;
        link.click();
    }
  }

  static distinct(myArr, prop): any[] {
      return myArr.filter((obj, pos, arr) => {
          return arr.map(mapObj =>
              mapObj[prop]).indexOf(obj[prop]) === pos;
      });
  }

  static getUsuarioSession() {
    const usuLocal = localStorage.getItem(`${NAME_PROJECT}.usuarioLogado`);
    return usuLocal ? JSON.parse(atob(usuLocal)) : null;
  }

  static isUserPresidente(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario.perfil.descricao.toUpperCase() === TipoPerfil.PC;
  }

  static isUserDEMOG(): boolean {
    const usuario = this.getUsuarioSession();

    return usuario.perfil.descricao.toUpperCase() === TipoPerfil.DEMOG;
  }

  static getOrgaoSession(): string {
    const orgao = localStorage.getItem(`${NAME_PROJECT}.orgao`);
    return orgao ? JSON.parse(atob(orgao)) : null;
  }

  static getOrgaoNameSession(): string {
    const orgao = localStorage.getItem(`${NAME_PROJECT}.orgaoNome`);
    return orgao ? JSON.parse(atob(orgao)) : null;
  }

  static getRestricoes(): string[] {
    const restricoes = localStorage.getItem(`${NAME_PROJECT}.restricoes`);
    return restricoes ? JSON.parse(atob(restricoes)) : null;
  }

  static getPerfil() {
    const perfil = localStorage.getItem(`${NAME_PROJECT}.perfil`);
    return perfil ? JSON.parse(atob(perfil)) : null;
  }

  static isSuperUser(): boolean {
    const isSuperUser = JSON.parse(localStorage.getItem(`${environment.namespaceProject}.isSuperUser`));
    return isSuperUser || false;
  }
  static verificaAdm(): boolean {
    const administrador = 'ADMINISTRADOR';
    const perfil = Util.getPerfil();
    if (perfil === administrador) {
      return true;
    } else {
      return false;
    }
  }
  static setOrgaoSession(orgaoId: string): void {
    localStorage.setItem(`${environment.namespaceProject}.orgao`, btoa(JSON.stringify(orgaoId)));
  }

  static setOrgaoNameSession(orgaoId: string, orgaoNome: string): void {
    localStorage.setItem(`${environment.namespaceProject}.orgao`, btoa(JSON.stringify(orgaoId)));
    localStorage.setItem(`${environment.namespaceProject}.orgaoNome`, btoa(JSON.stringify(orgaoNome)));
  }

  static getListYear(): any[] {
    const yearToday = new Date().getFullYear();
    const range = [];
    range.push(yearToday);
    for (let i = 1; i < 12; i++) {
      range.push(yearToday - i);
    }
    return range;
  }

  static getRandomColor() {
    const color = Math.floor(0x1000000 * Math.random()).toString(16);
    return '#' + ('000000' + color).slice(-6);
  }

  static convertStringBoolean(value: string | boolean ): boolean|string {
    if (value === ('Sim'  || 'SIM' || 'sim' || 'Ativo')) {
      return true;
    }

    if (value === ('Não' || 'NAO' || 'não' || 'Inativo')) {
      return false;
    }

    if (value === true) {
      return 'Ativo';
    }

    if (value === false) {
      return 'Inativo';
    }
  }

  static stringToBoolean(value: boolean): string {
    if (value === true) {
      return 'Sim';
    }

    if (value === false) {
      return 'Não';
    }
  }

  static mascaraCpf(valor): string {
    return valor.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/g, '\$1.\$2.\$3\-\$4');
  }

  static mascaraTelefone(valor): string {
    return valor.replace(/^(\d\d)(\d{5})(\d{4}).*/, '($1) $2-$3');
  }

  static mascaraCep(valor): string {
    return valor.replace(/\.|\-/g, '');
  }

  static revertDate(valor): string {
    const ano = valor.substring(0, 4);
    const mes = valor.substring(5, 7);
    const dia = valor.substring(8, 10);

    return dia + '/' + mes + '/' + ano;
  }

   /**
   * Função que converte uma string com o formato 'YYYY-MM-DD' em uma data
   * @param date data em string a ser convertida para o formato de data
   */

   static formatStringToDate(date: string) {
    return date && new Date(
      Number(date.slice(0, 4)),
      Number(date.slice(5, 7)) - 1,
      Number(date.slice(8, 10))
    );
  }


  static getFormattedPrice(price: number): string {
    return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(price);
  }

  static setColorStatus(value: string): SetColorInterface {
    if (['Finalizado', 'Sim', 'Ativo', 'Lida'].includes(value)) {
      const status = 'badge badge-pill badge-success';
      return { boolean: true, data: status } ;
    }

    if (['Não', 'Inativo', 'Não lida'].includes(value)) {
      const status = 'badge badge-pill badge-danger';
      return { boolean: true, data: status } ;
    }

    if (value === 'Em Andamento') {
      const status = 'badge badge-pill badge-warning';
      return { boolean: true, data: status } ;
    }

    return { boolean: false };
  }

  /**
   * Função para desabilitar os campos de um formulário
   * @param formGroup variavel que traz o form group
   */
   static disabledForm(formGroup: FormGroup) {
    // (formGroup.controls).forEach(key => {
    //   formGroup.controls[key].disable = true;
    // });
    formGroup.disable();
  }

  static setColorTheme() {
    const isSuperUser = Util.isSuperUser();
    const rootStyle = document.documentElement.style;

    const primary = isSuperUser ? '#000000'  : '#2484C6';
    const primaryShadow = isSuperUser ? '#00000030' : '#2484c640';
    const primaryDark = isSuperUser ? '#000000d6' : '#00407D';
    const secondary = isSuperUser ? '#ff0202' : '#00407D';

    rootStyle.setProperty('--primary-color', primary, 'important');
    rootStyle.setProperty('--primary-shadow-color', primaryShadow, 'important');
    rootStyle.setProperty('--primary-dark-color', primaryDark, 'important');
    rootStyle.setProperty('--secondary-color', secondary, 'important');
  }

  static setColorThemeV2() {
    const rootStyle = document.documentElement.style;

    const primary =  '#000000';
    const primaryShadow = '#00000030';
    const primaryDark = '#000000d6';
    const secondary = '#ff0202';

    rootStyle.setProperty('--primary-color', primary, 'important');
    rootStyle.setProperty('--primary-shadow-color', primaryShadow, 'important');
    rootStyle.setProperty('--primary-dark-color', primaryDark, 'important');
    rootStyle.setProperty('--secondary-color', secondary, 'important');
  }

  /**
   * Verifica se há algum campo preenchido no objeto ou array
   * @param object objeto a ser verificado
   * @returns true caso haja algum valor preenchido, senão false
   */
   static objectHasAnyValue(object: any) {
    return Object.keys(object).some(key => {
      const value = object[key];
      return typeof value === 'boolean' ||
              (Array.isArray(value) ? value.length > 0 : value) ||
              value?.trim();
    });
  }

  static getOrgaoLogado() {
    const orgao = localStorage.getItem(`${NAME_PROJECT}.orgao`);
    return orgao ? JSON.parse(atob(orgao)) : null;
  }

  static getUsuarioLogado() {
    const orgao = localStorage.getItem(`${NAME_PROJECT}.usuarioLogado`);
    return orgao ? JSON.parse(atob(orgao)) : null;
  }

  static setStatusPlanoAtivo(status: string): void {
    localStorage.setItem(`${environment.namespaceProject}.plano-atual`, btoa(JSON.stringify(status)));
  }

  static getStatusPlanoAtivo() {
    const planoCriptografado = localStorage.getItem(`${NAME_PROJECT}.plano-atual`);
    if (!planoCriptografado) {
      return '';
    }
    const plano = JSON.parse(atob(planoCriptografado));
    if (!plano.statusPlano) {
      return '';
    }
    return plano.statusPlano;
  }

  /**
   * @returns classe css para alterar a cor do componente led-app
   */
  static getStatusPlanoCssClass() {
    const statusPlano = Util.getStatusPlanoAtivo();
    switch (statusPlano) {
      case Status.APROVADO:
        return 'aprovado';
      case Status.PEN_APROVACAO:
        return 'pendente-aprovacao';
      case Status.PEN_CORRECAO:
        return 'pendente-correcao';
      case Status.PEN_SUBMISSAO:
        return 'pendente-submissao';
      default:
        return 'pendente-correcao';
    }
  }

  /**
   * @returns classe com o badge vermelho ou verde de acordo com o status
   */
  static getColorStatus(value: string): string {
    if (['Finalizado', 'Sim', 'Ativo', 'true'].includes(value)) {
      return 'badge badge-pill badge-success';
    }

    if (['Não', 'Inativo', 'false'].includes(value)) {
      return 'badge badge-pill badge-danger';
    }

    return '';
  }

  static isDateValid(dateString: any): boolean{

    const date = new Date(dateString);
    const isValidDate = !isNaN(date.getFullYear()) && date.getFullYear().toString() !== "1";

    return isValidDate;
  }

  static isGuidEmpty(guid: string): boolean {
    return guid.trim() === '' || guid === '00000000-0000-0000-0000-000000000000';
  }

  static getPtLocale(){
    return {
      firstDayOfWeek: 0,
      dayNames: [
        'Domingo',
        'Segunda',
        'Terça',
        'Quarta',
        'Quinta',
        'Sexta',
        'Sábado',
      ],
      dayNamesShort: ['Dom', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sáb'],
      dayNamesMin: ['D', 'S', 'T', 'Q', 'Q', 'S', 'S'],
      // dayNamesMin: ["Su","Mo","Tu","We","Th","Fr","Sa"],
      monthNames: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
      monthNamesShort: [
        'Jan',
        'Fev',
        'Mar',
        'Abr',
        'Mai',
        'Jun',
        'Jul',
        'Ago',
        'Set',
        'Out',
        'Nov',
        'Dez',
      ],
      today: 'Hoje',
      clear: 'Limpar',
      weekHeader: 'Semana',
    };
  }
  static getOptionText(value: boolean | string): string {
    if (value === '') {
      return 'Selecione';
    } else if (value === true) {
      return 'Sim';
    } else if (value === false) {
      return 'Não';
    } else {
      return String(value);
    }
  }

  static getEditarTaxaServico() {
    const editar = localStorage.getItem(`editar_taxa_servico`);
    return editar ? JSON.parse(atob(editar)) : null;
  }

  static formatNameDocument(event, form) {
    const input = event.target as HTMLInputElement;
    let formattedValue = '';

    if (input.value) {
      const words = input.value.split(' ');

      for (let i = 0; i < words.length; i++) {
        if (words[i]) {
          formattedValue +=
            words[i].charAt(0).toUpperCase() + words[i].slice(1);

          if (i < words.length - 1) {
            formattedValue += ' ';
          }
        }
      }
    }
    form.patchValue({ nome: formattedValue });
  }
}
