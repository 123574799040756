<app-template-orgao-modal (close)="closeModal()" [botaoSair]="desativarBotaoSairModal">
<div class="container-fluid" conteudo [formGroup]="form">
  <div class="container">
    <form [formGroup]="form" (keydown.enter)="$event.preventDefault()">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <label for="titulo">Para acessar o sistema, por favor, selecione o órgão abaixo *</label>
              <p-autoComplete
              placeholder="Selecione/Busque o órgão para ter acesso ao sistema"
              class="custom-auto-complete custom-auto-complete-radius"
              formControlName="orgaoId"
              [delay]="900"
              dataKey="id"
              [suggestions]="orgaoList"
              [forceSelection]="true"
              (completeMethod)="searchOrgao($event)"
              field="descricao"
              [dropdown]="true"
              (onDropdownClick)="getAllOrgao()"
              [emptyMessage]="'Nenhum resultado encontrado'"
              minLength="2"
              [ngClass]="setErrorValidate('orgaoId')"
              (focusout)="updateErrors()"
            >
            </p-autoComplete>
            </div>
          </div>
        </div>
      <div class="row">
        <div class="col-md-12 botao-acessar" [hidden]="selecionarOrgaoInicial">
            <button type="submit" (click)="onSubmit()" [disabled]="!enableSending()" class="save btn rounded-pill btn-primary float-center ml-1 mb-5 col-md-4">
              Acessar
            </button>
        </div>
        <div class="col-md-12 botao-acessar" [hidden]="selecionarOrgaoBotao">
          <button type="submit" (click)="closeModal()" class="save btn rounded-pill btn-primary float-center ml-1 mb-5 col-md-4">
            Cancelar
          </button>
          <button type="submit" (click)="onSubmit()" [disabled]="!enableSending()" class="save btn rounded-pill btn-primary float-center ml-1 mb-5 col-md-4">
            Trocar de Órgão
          </button>
      </div>
      </div>
    </form>
  </div>

</div>
</app-template-orgao-modal>
