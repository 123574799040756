<div class="modal-header">
  <div class="row">
    <div class="col-md-12 logo">
      <img alt="Logo" class="displayed brand-image p-0 img-fluid ml-2"
        src="assets/img/new-logo-pb.png">
    </div>
  </div>
    <div class="row">
      <div class="col-md-12 close-modal" [hidden]="botaoSair">
        <label for="sair">Sair</label>
      <button type="button" class="close" data-dismiss="modal" aria-label="Close"  title="fechar" (click)="closeModal()" [routerLink]="['/auth/logout']">
        <i class="fas fa-sign-out-alt close-icon"></i>
      </button>
      </div>
    </div>

  </div>
  <div>
    <ng-content select="[conteudo]"></ng-content>
  </div>
