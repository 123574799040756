import { Guid } from 'guid-typescript';
import { Util } from 'src/app/shared/utils/util';

export class NotificacaoListDto {

  public id: Guid | string;
  public mensagem: string;
  public status: string;
  public dataNotificacao: string;
  public tabelaModificadaId: Guid;
  public tempo: number;
  public tipoNotificacaoId: Guid | string;
  public orgaoNome: string;
  public usuarioAcao: string;

  constructor(object?: any) {
    this.id                 = object.id;
    this.mensagem           = object.mensagem;
    this.status             = object.status;
    this.dataNotificacao    = object.dataNotificacao;
    this.tabelaModificadaId = object.tabelaModificadaId;
    this.tempo              = object.tempo;
    this.tipoNotificacaoId  = object.tipoNotificacaoId;
    this.orgaoNome          = object.orgaoNome;
    this.usuarioAcao        = object.usuarioAcao;
  }

}

