import { RestricaoGenericos } from './restricao-generic';

export const restricaoUsuario = 'USUARIO_';


export const RestricaoUsuario = {
  descricao: 'Usuário',

  USUARIO_ALL             : `${restricaoUsuario}${RestricaoGenericos.ALL}`,
  USUARIO_REMOVE          : `${restricaoUsuario}${RestricaoGenericos.REMOVE}`,
  USUARIO_CREATE_UPDATE   : `${restricaoUsuario}${RestricaoGenericos.CREATE_UPDATE}`,
  USUARIO_VIEW            : `${restricaoUsuario}${RestricaoGenericos.VIEW}`,
  USUARIO_ACTIVE_INACTIVE : `${restricaoUsuario}${RestricaoGenericos.ACTIVE_INACTIVE}`,
};
