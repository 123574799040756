import { Guid } from 'guid-typescript';
import { Util } from 'src/app/shared/utils/util';
import { HorarioServicoDto } from './horario-servico.dto';
import { TaxaServicoDto } from './taxa-servico.dto';

export class ServicoTableDto {
  public id?: string | Guid;
  public servicoOferecido: string;
  public tipoTaxa: string;
  public taxasServico: TaxaServicoDto[];
  public publicoAlvo: string[];
  public formaPrestacao: string[];
  public formaPrestacaoUrl: string;
  public formasPrestacaoOnline: string[];
  public descricaoServico: string;
  public exigencias: string;
  public informacaoAdicional: string;
  public etapasProcessamentoServico: string;
  public tempoEsperaAtendimento?: number;
  public tempoEsperaAtendimentoTipo: string;
  public tempoMedioAtendimento?: number;
  public tempoMedioAtendimentoTipo: string;
  public prazoRealizacao?: number;
  public prazoRealizacaoTipo: string;
  public prazoMaximoRealizacao?: number;
  public prazoMaximoRealizacaoTipo: string;
  public horariosAtendimento: string;
  public volumeAnualSolitacoes?: number;
  public canalDigitalSolicitarServico?: boolean | string;
  public canalDigitalSolicitarServicoDescricao?: string;
  public usoCpfFormulario?: boolean | string;
  public tipoProjeto?: string;
  public acao: string;
  public passivelTransformacao?: boolean;
  public editInTable?: boolean;
  public quantoTempoLeva: string;
  public horarios: HorarioServicoDto[];
  public categoriaId?: string | Guid;
  public documentacoes: string[];
  public unidades: string[];
  public publicoAlvos: string[];
  public orgaoUrl: string;
  public consultaUnidadesFisicasServico: string;

  constructor(object?: any) {
  this.id                                 = object.id;
  this.acao                               = object.acao;
  this.servicoOferecido                   = object.servicoOferecido;
  this.descricaoServico                   = object.descricaoServico;
  this.tipoTaxa                           = object.tipoTaxa;
  this.taxasServico                       = object.taxasServico;
  this.publicoAlvo                        = object.publicoAlvo;
  this.formaPrestacao                     = object.formaPrestacao;
  this.formaPrestacaoUrl                  = object.formaPrestacaoUrl;
  this.formasPrestacaoOnline              = object.formasPrestacaoOnline;
  this.exigencias                          = object.exigencias;
  this.informacaoAdicional                 = object.informacaoAdicional;
  this.etapasProcessamentoServico          = object.etapasProcessamentoServico;
  this.tempoEsperaAtendimento              = Number(object.tempoEsperaAtendimento);
  this.tempoEsperaAtendimentoTipo          = object.tempoEsperaAtendimentoTipo;
  this.tempoMedioAtendimento               = Number(object.tempoMedioAtendimento);
  this.tempoMedioAtendimentoTipo           = object.tempoMedioAtendimentoTipo;
  this.tempoEsperaAtendimentoTipo          = object.tempoEsperaAtendimentoTipo;
  this.prazoRealizacao                     = Number(object.prazoRealizacao);
  this.prazoRealizacaoTipo                 = object.prazoRealizacaoTipo;
  this.prazoMaximoRealizacao               = Number(object.prazoMaximoRealizacao);
  this.prazoMaximoRealizacaoTipo           = object.prazoMaximoRealizacaoTipo;
  this.horariosAtendimento                 = object.horariosAtendimento;
  this.canalDigitalSolicitarServico        = Util.convertStringBoolean(object.canalDigitalSolicitarServico);
  this.canalDigitalSolicitarServicoDescricao = object.canalDigitalSolicitarServicoDescricao;
  this.usoCpfFormulario                     = Util.convertStringBoolean(object.usoCpfFormulario);
  this.tipoProjeto                          = object.tipoProjeto;
  this.volumeAnualSolitacoes                = Number(object.volumeAnualSolitacoes);
  this.passivelTransformacao                = object.passivelTransformacao;
  this.editInTable                          = object.editInTable;
  this.quantoTempoLeva                      = object.quantoTempoLeva;
  this.horarios                             = object.horarios.filter(x => x.horarioInicio && x.horarioFim);
  this.categoriaId                          = object.categoria?.id;
  this.documentacoes                        = object.documentacoes?.map(doc => doc.id);
  this.unidades                             = object.unidades?.map(un => un.id);
  this.publicoAlvos                         = object.publicoAlvos?.map(doc => doc.id);
  this.orgaoUrl                             = object.orgaoUrl;
  this.consultaUnidadesFisicasServico       = object.consultaUnidadesFisicasServico;
  }
}
