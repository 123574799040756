import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Status } from 'src/app/core/enums/plano/status.enum';

@Component({
  selector: 'app-led',
  templateUrl: './led.component.html',
  styleUrls: [ './led.component.scss' ]
})
export class LedComponent implements OnInit, OnChanges {

  @Input() status: Status;
  @Input() classe: string = 'circulo';
  public ledColor: string;

  constructor() { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.status.currentValue) {
      this.status = changes.status.currentValue;
    }
  }

  public getLedColor() {
    switch (this.status) {
      case Status.APROVADO.toString():
        return 'aprovado';
      case Status.PEN_APROVACAO.toString():
        return 'pendente-aprovacao';
      case Status.PEN_CORRECAO.toString():
        return 'pendente-correcao';
      case Status.PEN_SUBMISSAO.toString():
        return 'pendente-submissao';
        case Status.CONCLUIDO.toString():
        return 'Plano Concluído';
    }
  }

}
