import { Component } from '@angular/core';
import { NgbActiveModal, NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-modal-view-unidade-fisica',
  templateUrl: './modal-view-unidade-fisica.component.html',
  styleUrls: ['./modal-view-unidade-fisica.component.scss']
})
export class ModalViewUnidadeFisicaComponent {
  public unidadeFisica;

  constructor(
    private activeModal: NgbActiveModal,
    public modalService: NgbModal
  ) { }

  public closeModal(): void {
    this.activeModal.close();
  }

  getEndereco(unidade) {
    return `${unidade.logradouro}, ${unidade.numero}, ${unidade.bairro}, ${unidade.cep}, ${unidade.unidades}`;
  }
}
