import { NgxPermissionsService } from 'ngx-permissions';
import { Router } from '@angular/router';
import { Util } from './util';

export class PermissionControl {

    private permissionUser = '';
    private restrictionsSystem = Util.getRestricoes();

    constructor(
      public router: Router,
      public permissionsService: NgxPermissionsService,
    ) { }


    /**
     * Função Adapata para setar a(s) permissões no NGX-PERMISSION com os perfil(s) do usuario
     * @param permissions Lista de funções que Pode acessar a tela ex: ['ADMIN', 'ADMIN_SES']
     * @param permissionUser Perfil o qual usuario possui ex: 'ADMIN_SES'
     * @param redirect Caso queria que essa função valide se quer redirecionar caso não tenha o perfil presente na lista de permissões
     *
     */
     public setPermissionInComponent(permissions: string[], permissionUser: string, redirect = false) {
      this.permissionUser = permissionUser;
      const existList = permissions.find(x => x.includes(permissionUser));
      if (!existList && redirect) {
        return this.router.navigate(['/sem-permissao']);
      }
      this.permissionsService.loadPermissions(permissions);
    }

    public hiddenComponenteWithAll(restrictions: string[]) {
      const rest = this.restrictionsSystem.filter(item => restrictions.indexOf(item) >= 0);

      if (rest.length === restrictions.length) {
          return false;
      }

      return true;
    }

   public setRestrictionInComponent(restrictions: string[] = [], redirect = false) {
      const restrictionsSystem = Util.getRestricoes();
      const existRestriction = restrictionsSystem.filter(item => restrictions.indexOf(item) >= 0);
      if (existRestriction.length > 0 && redirect) {
          return this.router.navigate(['/']);
      }

      this.permissionsService.loadPermissions(restrictionsSystem);
  }

  public hiddenComponenteRestriction(restrictions: string[]) {

    let rest: string[] = new Array<string>();

    for (const restricao of restrictions) {
      const res = this.restrictionsSystem.filter(item => restricao.indexOf(item) >= 0);
      if (res.length > 0) {
        rest.push(res[0]);
      }
    }

    if (rest.length > 0) {
        return false;
    }

    return true;
  }
}
