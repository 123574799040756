<div class="card border border-primary">
  <div class="card-header">
    <div class="card-title"><strong>{{ title }}</strong></div>
    <div class="card-tools">
      <button type="button" class="btn text-muted" data-card-widget="collapse"><em class="fas fa-minus"></em>
      </button>
    </div>
  </div>

  <div class="card-body">
    <ng-content select="[form]"></ng-content>
  </div>
</div>
