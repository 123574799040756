import { AfterContentChecked, Component, DoCheck, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { NgxPermissionsService } from 'ngx-permissions';
import { map } from 'rxjs/operators';
import { EtapaInfoDto } from 'src/app/core/dto/etapa/etapa-info-dto';
import { Status } from 'src/app/core/enums/plano/status.enum';
import { RestricaoPerfil } from 'src/app/core/enums/restricoes/restricao-perfil';
import { EtapasService } from 'src/app/core/services/etapas.service';
import { OrgaoHandlerService } from 'src/app/modules/orgao/service/orgao-handler-service.service';
import { PermissionControl } from 'src/app/shared/utils/permission-control';
import { Util } from 'src/app/shared/utils/util';

declare var $: any;

@Component({
  selector: 'app-sidebar-auth',
  templateUrl: './sidebar-auth.component.html',
  styleUrls: ['./sidebar-auth.component.scss']
})
export class SidebarAuthComponent extends PermissionControl implements OnInit, AfterContentChecked, DoCheck {

  public usuarioSession;
  public id: string;
  public perfilType: string;
  public isFinish: boolean;
  public usuario: string;
  public isSuperUser = Util.isSuperUser();
  public usuarioAdm = Util.verificaAdm();
  private usuarioSessao;
  public statusPlano: Status;
  public restricaoPerfil = RestricaoPerfil;

  constructor(
    public router: Router,
    public permissionsService: NgxPermissionsService,
    private etapasService: EtapasService,
    private orgaoHandlerService: OrgaoHandlerService,
    ) {
      super(router, permissionsService);
      this.setRestrictionInComponent();
   }

  ngDoCheck(): void {
    this.id = Util.getOrgaoSession();
    this.perfilType = Util.getPerfil();
    this.isFinish = this.orgaoHandlerService.getFinish;
  }

  ngOnInit(): void {
    $(document).ready(() => {
      $('[data-widget="treeview"]').Treeview('init');
      $('.menu-item').click(() => {
        $('.nav-icon').toggleClass('open');
      });
    });
    this.getCurrentUserSession();
    this.isFinish = this.orgaoHandlerService.getFinish;
    if (this.id) {
      this.getEtapas();
    }

    this.usuarioSessao = Util.getUsuarioLogado();
    this.usuario = this.usuarioSessao.nome;
  }

  ngAfterContentChecked() {
    this.activeSideBar();
  }

  get orgaoId() {
    return Util.getOrgaoSession();
  }

  public verifyRouter(): string {
    if (this.id) {
      return '/orgao/visualizar/';
    }
    return '/orgao/listar';
  }

  public activeSideBar() {
    const baseUrl = window.location.origin;
    const url = window.location.href.replace(baseUrl, '');
    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).removeClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') !== url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .removeClass('menu-open')
      .prev('a')
      .removeClass('active');

    $('ul.nav-sidebar a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).addClass('active');

    // for treeview
    $('ul.nav-treeview a').filter(function() {
      return this.href.replace(baseUrl, '') === url;
    }).parentsUntil('.nav-sidebar > .nav-treeview')
      .addClass('menu-open')
      .prev('a')
      .addClass('active');
  }

  public getCurrentUserSession() {
      this.usuarioSession = Util.getUsuarioSession();
  }

  public getEtapas(): void {
    this.etapasService.getById(this.id).pipe(map((res) => new EtapaInfoDto(res.body.data) ))
    .subscribe((res: EtapaInfoDto) => {
      this.isFinished(res);
    });
  }

  public isFinished(value: EtapaInfoDto): void {
    if ( value.situacaoCadastral === true &&
    value.historico === true &&
    value.competencia === true &&
    value.servico === true) {
      this.orgaoHandlerService.setFinish = true;
      return;
    }

    this.orgaoHandlerService.setFinish = false;
    return;
    }

    get verificaUsuario(): boolean {
      const perfil = Util.getPerfil();
      if (perfil === 'AVALIADOR' || perfil === 'MANDATÁRIO DO ORGÃO') {
        return true;
      } else {
        return false;
      }
    }

    public getRotaConferencia() {
      if (Util.verificaAdm()) {
        return '/conferencia/avaliacao';
      }

      return '/conferencia/submeter';
    }

    getStatus() {
      return Util.getStatusPlanoAtivo();
    }

    public exibirConferencia() {
      const statusPlano = Util.getStatusPlanoAtivo();

      if (Util.verificaAdm() && statusPlano.length > 0)  {
        if ( statusPlano !== Status.PEN_SUBMISSAO.toString() && statusPlano !== Status.PEN_CORRECAO.toString()) {
          return true;
        }
        return false;
      }

      if (!Util.verificaAdm() && statusPlano.length > 0) {
        if( statusPlano === Status.CONCLUIDO.toString()){
          return false;
         }
        return true;
      }

      return false;
    }

  public administradorOrgaoSelecionado():boolean {
    if (this.orgaoId) {
      if (!Util.isGuidEmpty(this.orgaoId)) {
        return true
      }
    }
  }

  get exibirMenu(): boolean {
    return this.orgaoId === '00000000-0000-0000-0000-000000000000';
  }

}
