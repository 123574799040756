import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { OrgaoViewModel } from 'src/app/core/dto/viewModelTable/orgao-view-model';
import { BaseService } from 'src/app/core/services/base.service';
import { urlConfigs } from 'src/app/core/utils/url-configs';

@Injectable({
  providedIn: 'root'
})
export class OrgaoService extends BaseService<any> {

  constructor(protected http: HttpClient) {
    super(http, `${urlConfigs.url_search_orgao}`);
  }

  public getAll(filters?: URLSearchParams): Observable<OrgaoViewModel[]> {
    return this.http.get(`${this.baseUrl}${this.path}?${filters ?? ''}`, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)))
      .pipe(map((data: any) => data.body.data.map(item => new OrgaoViewModel(item))));
  }

  inactivate(id: string, ativo: boolean): Observable<any> {
    return this.httpClient
      .patch(`${this.baseUrl}${this.path}/${id}`, !ativo, this.HeaderOptions())
      .pipe(catchError((e: HttpErrorResponse) => throwError(e)));
  }
}
