import { FormasPrestacaoOnlineDto } from "../orgao/input/update/forma-prestacao-online-servico.dto";

export class  ServicoDashBoardDetalhamentoDto {
  public nome: string;
  public formaPrestacao: string[];
  public url: string;
  public descricaoServico: string;
  public exigencias: string;
  public quantoTempoLeva: string;
  public informacaoAdicional: string;
  public etapasProcessamentoServico: string;
  public publicoAlvo: string[];
  public orgaoNome: string;
  public orgaoSite: string;
  public taxaServico: string;
  public taxaServicoValor?: number;
  public tipoTaxa?: string;
  public logradouro: string;
  public cep: string;
  public numero: string;
  public sede: string;
  public telefone: string;
  public documentacaoNecessaria: string[];
  public urls: FormasPrestacaoOnlineDto[];
  public consultaUnidadesFisicasServico: string;


  constructor(object: any) {
      if (object) {
          Object.assign(this, object);
      }
  }
}
