import { Guid } from 'guid-typescript';
import { Util } from 'src/app/shared/utils/util';
import { ServicoTableDto } from '../../view/servico-table-dto';

export class ServicoOrgaoUpdateDto {
  public orgaoId: Guid | string;
  public isFinalizar: string | boolean;
  public servicos: ServicoTableDto[] = new Array<ServicoTableDto>();

  constructor(object?: any) {
    if (object) {
    this.orgaoId                    = object.orgaoId;
    this.isFinalizar                = Util.convertStringBoolean(object.isFinalizar);
    this.servicos                   = object.servicos;
  }
}
}
