<app-template-modal [exibirFecharSuperior]="false" [classAdicional]="'btn-dark'">
  <div class="container-fluid" conteudo>
    <div class="col-12">
        <div class="col mt-3 text-center">
            <h1>
                {{ titleModal }}
            </h1>
        </div>
    </div>

    <div class="text-center mt-3">
        <h2>Rede PB Digital</h2>
    </div>

    <div class="col-12">
      <app-card-form [title]="'Filtro:'">
        <div form>
          <form [formGroup]="form" (keydown.enter)="$event.preventDefault(); submit();">
            <div class="container-fluid">
              <div class="row">
                <div class="col-6 col-md-6">
                  <div class="form-group">
                    <p-autoComplete
                        id="orgaoId"
                        placeholder="Digite o nome do Órgão..."
                        class="custom-auto-complete custom-auto-complete-radius"
                        formControlName="orgaoId"
                        [delay]="900"
                        dataKey="id"
                        [suggestions]="orgaoList"
                        [forceSelection]="true"
                        (completeMethod)="searchOrgao($event)"
                        field="descricao"
                        [dropdown]="true"
                        (onDropdownClick)="getAllOrgao()"
                        [emptyMessage]="'Nenhum resultado encontrado'"
                        minLength="2"
                        [ngClass]="setErrorValidate('orgaoSelectedId')"
                        (focusout)="updateErrors()"
                      >
                      </p-autoComplete>
                  </div>
                </div>

                <div class="col-6 col-md-6">
                  <div class="form-group">
                    <p-autoComplete
                        id="servicoId"
                        placeholder="Digite o nome do Serviço..."
                        class="custom-auto-complete custom-auto-complete-radius"
                        formControlName="servicoId"
                        [delay]="900"
                        dataKey="id"
                        [suggestions]="servicosList"
                        [forceSelection]="true"
                        (completeMethod)="searchServico($event)"
                        field="descricao"
                        [dropdown]="true"
                        (onDropdownClick)="getAllServico()"
                        [emptyMessage]="'Nenhum resultado encontrado'"
                        minLength="2"
                        [ngClass]="setErrorValidate('servicoSelectedId')"
                        (focusout)="updateErrors()"
                      >
                      </p-autoComplete>
                  </div>
                </div>

              </div>

              <div class="row justify-content-end">
                <button
                id="clearFilter"
                type="button"
                class="btn btn-warning-light rounded-pill mb-2 col-12 col-sm-6 col-md-auto"
                (click)="clearFilter()"
              >
                <em class="fa fa-eraser"></em>
                Limpar
              </button>
              <button
                id="submit"
                type="submit"
                class="btn btn-dark rounded-pill mb-2 col-12 col-sm-6 col-md-auto ml-md-2"
                [disabled]="enableShipping()"
                (click)="submit()"
              >
                <em class="fa fa-search"></em>
                {{ buttonSubmit.buttonText }} &nbsp;
                <em class="fa fa-spinner fa-spin"
                *ngIf="buttonSubmit.buttonSubmited"></em>
                Buscar
              </button>
              </div>
            </div>
          </form>
        </div>
      </app-card-form>
    </div>

    <div id="listagem" class="row scroll-div mb-4">
        <div class="col-md-12">
          <div class="col-12 col-md-12">
            <app-table-custom [items]="servicos" [itemsPerPage]="10">
              <ng-template appTableHeader>
                <tr class="th-color">
                  <th id="orgao">ÓRGÃO</th>
                  <th id="servico">SERVIÇOS</th>
                  <th id="forma-prestacao">FORMA DE PRESTAÇÃO</th>
                  <th id="detalhar">DETALHAR SERVIÇO</th>
                </tr>
              </ng-template>
              <ng-template appTableBody let-item>
                <tr>
                  <td class="text-capitalize">
                    {{ item.orgaoNome }}
                  </td>
                  <td class="text-capitalize">
                    {{ item.nome }}
                  </td>
                  <td class="text-capitalize">
                    {{ item.formaPrestacao }}
                  </td>
                  <td>
                    <button
                      id = "detalhar"
                      title="Detalhar serviço"
                      class="btn btn-sm mr-2"
                      (click)="viewServico(item)"
                    >
                      <em class="fas fa-search"></em>
                    </button>
                  </td>
                </tr>
              </ng-template>
              <ng-template appTableEmpty>
                <tr>
                  <td colspan="4" class="text-center">
                    Nenhum serviço {{ formaPrestacao || '' }} disponível
                  </td>
                </tr>
              </ng-template>
            </app-table-custom>
          </div>
        </div>
    </div>
    <button
      id="fechar"
      class="btn btn-dark rounded-pill float-right mb-3 col-md-2"
      (click)="closeModal()">
        Fechar
    </button>
  </div>
</app-template-modal>
