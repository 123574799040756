export const MSG_SUCCES = 'Operação realizada com sucesso.';
export const MSG_ADD_SUCESS = 'Registro adicionado com sucesso!';
export const MSG_EDIT_SUCESS = 'Registro editado com sucesso!';
export const MSG_DUPLICATE_SUCESS = 'Registro duplicado com sucesso!';
export const MSG_SUCCES_ORGAO = 'Cadastro concluído com sucesso.';
export const MSG_FALIELD = 'Falha na operação.';
export const MSG_QUESTION_COMPETENCIA = 'Deseja Excluir essa Competência?';
export const MSG_DELETE = 'Deseja Excluir';
export const MSG_QUESTION_DELETE = 'Deseja realmente excluir registro?';
export const MSG_DELETE_SUCESS = 'Registro excluído com sucesso!';
export const MSG_QUESTION_INACTIVATE = 'Deseja Inativar';
export const MSG_QUESTION_INACTIVATE_MANDATARIO = 'Deseja Inativar este mandatário para este órgão?';
export const MSG_QUESTION_ACTIVATE_MANDATARIO = 'Deseja Ativar este mandatário para este órgão?';
export const MSG_INACTIVATE = 'Deseja Inativar';
export const MSG_ACTIVATE = 'Deseja Ativar';
export const MSG_QUESTION_INACTIVE = 'Deseja realmente inativar o registro?';
export const MSG_QUESTION_ACTIVETE = 'Deseja realmente ativar o registro?';
export const MSG_QUESTION_ACTIVATE = 'Deseja Ativar';
export const MSG_FAIL_PDF = 'Por gentileza anexe um PDF';
export const MSG_SEND = 'Deseja realmente enviar os dados para validação? ';
export const MSG_FAIL_PDF_JPG = 'Por gentileza anexe um arquivo PDF ou JPG.';
export const MSG_FAIL_PDF_JPG_PNG = 'Por gentileza anexe um arquivo PDF, JPG OU PNG.';
export const MSG_VALIDATE_PLANO = 'Plano de Transformação Digital validado com sucesso!';
export const MSG_SUBMIT_PLANO = 'Plano de Transformação Digital submetido com sucesso!';
export const MSG_QUESTION_INVALID_PLANO = 'Tem certeza de que deseja invalidar este Plano de Transformação Digital?';
export const MSG_QUESTION_VALID_PLANO = 'Tem certeza que deseja validar este Plano de Transformação Digital?';
export const MSG_VALID_SUCCESS = 'A validação foi realizada com sucesso!';
export const MSG_INVALID_SUCCESS = 'A invalidação foi realizada com sucesso!';
export const MSG_SUCCESS_ALL_TABS = 'Plano de Transformação Digital aprovado com sucesso!';
export const MSG_SUCCESS_SUBMIT = 'Plano de Transformação Digital enviado com sucesso! Aguarde a avaliação do seu plano para seguir com os próximos passos.';
export const MSG_VALIDATE_SERVICO = 'O serviço foi verificado com sucesso.';
export const MSG_INVALID_SERVICO = 'O serviço foi invalidado! O Órgão irá receber uma notificação informando os próximos passos para realizar nova submissão.';
export const MSG_VALIDATE_JUSTIFY = 'É necessário adicionar uma justificativa ao validar/invalidar um serviço.'
export const MSG_INVALID_SERVICO_ONLINE = 'O serviço foi invalidado!';
export const DESVINCULAR_SERVICO_PLANO = 'A desvinculação do serviço do plano não é possível devido à existência de apenas um serviço vinculado.';
export const MSG_TIME_OUT = 'Sessão encerrada por inatividade.';
export const MSG_CEP = 'Preenchimento via CEP indisponível no momento. Por favor inserir manualmente as informações necessárias.';
export const MSG_TELEFONE_PRINCIPAL = 'O telefone principal não pode ser idêntico ao telefone alternativo. Por favor, insira um número diferente.';
export const MSG_CANCELAR_PUBLICO_ALVO = 'Você tem certeza de que deseja sair sem salvar as alterações?';
export const MSG_EXCLUIR_PUBLICO_ALVO = 'Você tem certeza que deseja excluir este Público Alvo?';
export const MSG_ATIVAR_PUBLICO_ALVO = 'Você tem certeza que deseja ativar este Público Alvo?';
export const MSG_INATIVAR_PUBLICO_ALVO = 'Você tem certeza que deseja inativar este Público Alvo? Ao inativar este registro, ele deixará de ser exibido em outros locais do sistema.';
export const MSG_ORGAO_SEM_NOME_REDESOCIAL = 'Você informou um endereço de perfil. Por favor, selecione o nome de uma rede social.';
export const MSG_ORGAO_SEM_ENDERECO_REDESOCIAL = 'Você selecionou uma rede social. Por favor, preencha o campo endereço de perfil.';
