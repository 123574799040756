import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { urlConfigs } from '../utils/url-configs';
import { BaseService } from './base.service';

@Injectable({
  providedIn: 'root'
})
export class AutoCompleteService extends BaseService<any> {

  constructor(protected http: HttpClient) {
    super(http, `${urlConfigs.url_autoComplete}`);
  }
}
