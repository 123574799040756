import { Component, OnInit } from '@angular/core';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-layout-no-auth',
  templateUrl: './layout-no-auth.component.html',
  styleUrls: ['./layout-no-auth.component.scss'],
})
export class LayoutNoAuthComponent implements OnInit {
  environment = environment;
  constructor() { }

  ngOnInit() { }

}
