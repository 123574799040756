import * as pdfMake from 'pdfmake/build/pdfmake';
import * as pdfFonts from 'pdfmake/build/vfs_fonts';
import { logoPbDigital } from './img-logo-pbdigital-base64';
import { ConferenciaPdfDto } from 'src/app/core/dto/conferencia/conferencia-pdf.dto';
import { DatePipe } from '@angular/common';
import { brasaoParaiba } from './img-brasao-paraiba-base64';
import { UtilPdf } from 'src/app/shared/utils/util.pdf';
import { Util } from 'src/app/shared/utils/util';
import { DetalhamentoOrgaopdfDto } from 'src/app/core/dto/graficos/orgao-pdf/detalhamento-orgao-pdf-dto';
(pdfMake as any).vfs = pdfFonts.pdfMake.vfs;

export class OrgaoDetalhesPdf {

  static gerarPdfOrgao(detalhamentoOrgaoPdf: DetalhamentoOrgaopdfDto) {

    const dataPipe = new DatePipe('pt-BR');

      const pdfConferenciaPlano = {
        info: {
          title: 'Dados do Órgão'
        },
        pageSize: 'A4',
        pageOrientation: 'portrait',
        pageMargins: [30, 60, 30, 30],
        header: this.printCabecalho(),
        footer: (currentPage, pageCount) => {
          // Código para exibir a numeração da página no rodapé
          return {
            text: `Página ${currentPage.toString()} de ${pageCount.toString()}`,
            style: 'pagination'
          };
        },


        background: function() {
           return {
            image: logoPbDigital,
            opacity: 0.10,
            absolutePosition: { x: 50, y: 50 },
            width: 500,
          }
           },

        content: [
          //titulo
          { text: 'Dados do Órgão', style: 'title', fontSize: 20, margin: 40 },
          { text: 'Informações Gerais do Órgão', style: 'subtitle' },

          {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'Código:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.codigo, aligment: 'right'},

                {text: 'Sigla do Órgão:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.sigla, aligment: 'right'},

                {text: 'Nome do Órgão:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.nome, aligment: 'right'},
            ],

          ]
        },
        margin: [0, 20, 0, 20],

        layout: 'noBorders'
      },

      {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'CEP:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.cep, aligment: 'right'},

                {text: 'Logradouro principal:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.logradouro, aligment: 'right'},

                {text: 'N°:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.numero, aligment: 'right'},

                {text: 'Município:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.municipio, aligment: 'right'},

                {text: 'Bairro:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.bairro, aligment: 'right'},
            ],


          ],
        },
        margin: [0, 0, 0, 20],


        layout: 'noBorders'
      },
      {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'Site Oficial:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.siteOficial, aligment: 'right'},

                {text: 'Telefone:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.telefone, aligment: 'right'},
            ],

          ]
        },

        margin: [0, 0, 0, 20],


        layout: 'noBorders'
      },
      {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'Tipo de administração:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.tipoAdministracao, aligment: 'right'},
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                {text: 'Ranking do órgão:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.ranking, aligment: 'right'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 20],
      },
      {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'Situação do plano:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.status, aligment: 'right'},
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                { text: '\u00A0', alignment: 'right' },
                {text: 'Andamento do plano:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.andamentoPlano, aligment: 'right'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 40],
      },

      //-------------------------------------------------------------------------------------------------------------------------------------------------
      ...(detalhamentoOrgaoPdf.informacoesOrgaoPdf.nomeAutoridadeMaxima
        ? [

      {
        style: 'tableExample',
        table: {
          widths: [500],
          headerRows: 2,
          // keepWithHeaderRows: 1,
          body: [
            [{text: 'Autoridadade Máxima do Orgão', alignment: 'center', style: 'tableHeader', fillColor: '#0000', color: 'white'}],
            [
              {text: 'Nome', style: 'tableHeader', bold: true, alignment: 'center', border: [true, true, true, true]},
            ],
            [
              {text: detalhamentoOrgaoPdf.informacoesOrgaoPdf.nomeAutoridadeMaxima, style: 'tableHeader', alignment: 'center', border: [true, true, true, true]}
            ]

          ],
        },

        margin: [0, 0, 0, 20],

     },

      UtilPdf.addLinha(),
           ]
         : []),
      ...(detalhamentoOrgaoPdf.informacoesOrgaoPdf.membros && detalhamentoOrgaoPdf.informacoesOrgaoPdf.membros.length > 0
        ? [
      {text: 'Informações do Membro da Comissão', style: 'subheader', width: [0, 0, 0, 20]},

      {
        style: 'tableExample',
        table: {
          widths: [250, 250],
          headerRows: 2,
          // keepWithHeaderRows: 1,
          body: [
            [{text: 'Listagem de Membros da Comissão', alignment: 'center', style: 'tableHeader', colSpan: 2, fillColor: '#0000', color: 'white'}, {}],
            [{text: 'Nome', style: 'tableHeader', bold: true, alignment: 'center', border: [true, false, false, false]}, {text: 'Perfil', style: 'tableHeader', bold: true, alignment: 'center', border: [false, false, true, true]}],
            ...detalhamentoOrgaoPdf.informacoesOrgaoPdf.membros.map(item => [
              {text: item.nome, style: 'tableHeader', alignment: 'center', border: [true, true, false, true]},
              {text: item.perfil, style: 'tableHeader', alignment: 'center', border: [false, true, true, true]},
            ])
          ],
        },

        margin: [0, 0, 0, 20],

     },
     UtilPdf.addLinha(),
          ]
        : []),


        ...(detalhamentoOrgaoPdf.informacoesOrgaoPdf.servicos && detalhamentoOrgaoPdf.informacoesOrgaoPdf.servicos.length > 0
          ? [
      {text: 'Informações do(s) Serviço(s) do Órgão', style: 'subheader', width: [0, 0, 0, 20]},

    {
        style: 'tableExample',
        table: {
          headerRows: 2,
          body: [
            [
              {text: 'Listagem de serviços', alignment: 'center', style: 'tableHeader', colSpan: 4, fillColor: '#0000', color: 'white'}, {}, {}, {}
            ],
            [
              {text: 'Nome do Serviço', style: 'tableHeader', bold: true, alignment: 'center', border: [true, false, false, false]},
              {text: 'Forma de prestação', style: 'tableHeader', bold: true, alignment: 'center', border: [false, false, false, true]},
              {text: 'Serviço passivo de transformação', style: 'tableHeader', bold: true, alignment: 'center', border: [false, false, false, true]},
              {text: 'Serviço transformado?', style: 'tableHeader', bold: true, alignment: 'center', border: [false, false, true, true]}
            ],
            ...detalhamentoOrgaoPdf.informacoesOrgaoPdf.servicos.map(item => [
              {text: item.nome, style: 'tableHeader', alignment: 'center', border: [true, true, false, true]},
              {text: item.formaPrestacao, style: 'tableHeader', alignment: 'center', border: [false, true, false, true]},
              {text: item.passivelTransformacao ? 'Sim' : 'Não', style: 'tableHeader', alignment: 'center', border: [false, true, false, true]},
              {text: item.transformado ? 'Sim' : 'Não', style: 'tableHeader', alignment: 'center', border: [false, true, true, true]}
            ])
          ]
        },
        margin: [0, 0, 0, 40]
      },
    ]
    : []),


    //----------------------------------------------------------------------------------------------------------------------------------------------
    ...(detalhamentoOrgaoPdf.informacoesPlanoPdf && detalhamentoOrgaoPdf.informacoesOrgaoPdf
      ? [
    { text: 'Dados do Órgão', style: 'title', fontSize: 20, margin: 40, pageBreak: 'before' },
      { text: 'Informações do Plano de Transformação Digital', style: 'subtitle' },

          {
        style: 'tableFont',
        table: {
          widths: 'auto',
          body: [
            [
                {text: 'Título do Plano de Transformação Digital:', bold: true},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.titulo},

                {text: 'Data de Criação:', bold: true, alignment: 'left'},
                {text: dataPipe.transform(detalhamentoOrgaoPdf.informacoesPlanoPdf?.dataCriacao), aligment: 'right'},

                {text: 'Status do Plano:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.status, aligment: 'right'},
                {text: 'Quantidade de Serviços Verificados:', bold: true, alignment: 'left'},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.validados, aligment: 'right'},
            ],

          ]
        },
        margin: [0, 20, 0, 20],

        layout: 'noBorders'
      },

      {
        style: 'tableFont',
        table: {
          widths: [75, '*'],
          body: [
            [
                {text: 'Apresentação:', bold: true},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.apresentacao, alignment: 'justify'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 20],

      },

      {
        style: 'tableFont',
        table: {
          widths: [75, '*'],
          body: [
            [
                {text: 'Objetivos do Plano:', bold: true},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.objetivosDoPlano, alignment: 'justify'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 20],
      },

      {
        style: 'tableFont',
        table: {
          widths: [75, '*'],
          body: [
            [
                {text: 'Estratégia de Transformação Digital:', bold: true},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.estrategias, alignment: 'justify'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 20],
      },

      {
        style: 'tableFont',
        table: {
          widths: [75, '*'],
          body: [
            [
                {text: 'Estratégia de Monitoramento:', bold: true},
                {text: detalhamentoOrgaoPdf.informacoesPlanoPdf?.monitoramento, alignment: 'justify'},
            ],

          ]
        },


        layout: 'noBorders',
        margin: [0, 0, 0, 20],
      },

      // --------------------------------------------------------------------------------------------------------------------------------------------

      { text: 'Serviços atrelados ao plano:', style: 'subtitle' },
      detalhamentoOrgaoPdf.informacoesPlanoPdf?.servicos.map(servico =>
        this.ServicoAtreladosTabela(servico))
      ]
      : []),
        ],
        columnGap: 20,
          margin: [20, 20, 20, 0],

        styles: {
          title: {
            fontSize: 20,
            bold: true,
            alignment: 'center',
            margin: [0, 0, 0, 20]
          },
          subtitle: {
            fontSize: 14,
            bold: true,
            margin: [0, 0, 0, 10]
          },
          label: {
            fontSize: 10,
            bold: true,
            margin: [0, 5, 0, 0]
          },
          input: {
            fontSize: 10,
            margin: [0, 0, 0, 10],
          },
          layout: {
            borderless: true // remova a borda da tabela
          },
          tableFont: {
            fontSize: 10,
          },
          tableFontPage2: {
              fontSize: 8
          },
          headerLine: {
            alignment: 'center',
            margin: [30, 0, 30, 0]
          },
          subheader: {
            fontSize: 16,
            bold: true,
            margin: [0, 0, 0, 40],
            alignment: 'left'
          },
          pagination: {
            fontSize: 10,
            margin: [20, 5, 20, 0],
            alignment: 'right',
          },
        }
      };

      pdfMake.createPdf(pdfConferenciaPlano).open();
    }
    public static printCabecalho(): any {

      const dataAtual = new Date();
      const diasDaSemana = ["domingo", "segunda", "terça", "quarta", "quinta", "sexta", "sábado"];
      const diaDaSemana = diasDaSemana[dataAtual.getDay()];

      const dataFormatada = dataAtual.toLocaleDateString('pt-BR') + ' ' + dataAtual.toLocaleTimeString('pt-BR');



      return  [

        {

          margin: [20, 10, 20, 0],
          fontSize: 10,

           columns: [
              {
                columns: [
                  { image: logoPbDigital,
                    width: 35,
                    height: 35,
                    alignment: 'left'
              },
              {
                margin:[10,0,0,0],
                alignment: 'left',
                width: 'auto',
                text: `\nData da Impressão: ${diaDaSemana},\n ${dataFormatada}`,
                fontSize: 8
              },
              {
                alignment: 'center',
                text: [{text:'Secretaria de Estado da Administração - Governo da Paraíba\n'},
                {text:'Centro Administrativo Estadual - Avenida Dr. João da Mata, nº 200 - Bloco 3\n'},
                {text:'Jaguaribe - João Pessoa/PB - CEP: 58015-900\n'},

              ]

              },
              {
                image: brasaoParaiba,
                width: 35,
                height: 35,
                alignment: 'right'

              }]
            },

            ],
          },
          UtilPdf.addLinha('vh')
      ]
  }

  public static ServicoAtreladosTabela(servico) {
    const dataPipe = new DatePipe('pt-BR');
    const tabelaServico = [
      {
        style: 'tableFont',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { text: 'Nome do Serviço:', bold: true, alignment: 'left' },
              { text: servico.nome, alignment: 'right' },
              Util.isDateValid(servico.dataAprovacao) && servico.percentualConclusao != '0' ? { text: 'Data de conclusão:', bold: true, alignment: 'left' } :{ text: 'Percentual de Conclusão:', bold: true, alignment: 'left' },
              Util.isDateValid(servico.dataAprovacao) && servico.percentualConclusao != '0' ? { text: dataPipe.transform(servico.dataAprovacao), alignment: 'right' } : { text: `${servico.percentualConclusao}%`, alignment: 'right' },
            ],
          ],
        },
        width: [20, 0, 0, 20],
        layout: 'noBorders',
      },
      {
        style: 'tableFont',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { text: 'Data de Início da transformação:', bold: true, alignment: 'left' },
              { text: dataPipe.transform(servico.dataInicioTransformacao), alignment: 'right' },
              { text: 'Data de Término da transformação:', bold: true, alignment: 'left' },
              { text: dataPipe.transform(servico.dataFimTransformacao), alignment: 'right' },
            ],
          ],
        },
        width: [0, 0, 0, 20],
        layout: 'noBorders',
      },
      !Util.isDateValid(servico.dataEnvio) ? UtilPdf.addLinha() : '',
    ];

    if (Util.isDateValid(servico.dataEnvio) && servico.percentualConclusao != '0') {
      tabelaServico.push({
        style: 'tableFont',
        table: {
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { text: 'Data de Verificação:', bold: true, alignment: 'left' },
              { text: dataPipe.transform(servico.dataEnvio), alignment: 'right' },
            ],
          ],
        },
        width: [0, 0, 0, 20],
        layout: 'noBorders',
      }, UtilPdf.addLinha(),
      );
    }

    return tabelaServico;
  }

}


