import { Guid } from 'guid-typescript';
import { Util } from 'src/app/shared/utils/util';
import { HorarioServicoDto } from './horario-servico.dto';
import { DadosServicoValidadoDto } from './dados-servico-validado-dto';
import { JustificativaInfoDto } from '../../conferencia/justificativa-info-dto';
import { FormasPrestacaoOnlineDto } from '../input/update/forma-prestacao-online-servico.dto';
import { TaxaServicoDto } from './taxa-servico.dto';

export class ServicosInfoDto {
  public id: string;
  public orgaoId: string | Guid;
  public categoria: string | Guid;
  public servicoOferecido: string;
  public taxas: TaxaServicoDto[];
  public tipoTaxa: string;
  public publicoAlvo: string[];
  public formaPrestacao: string[];
  public formaPrestacaoUrl: string;
  public formasPrestacaoOnline: FormasPrestacaoOnlineDto[];
  public formaPrestacaoEditavel: boolean;
  public andamentoDigital: string;
  public descricaoServico: string;
  public exigencias: string;
  public informacaoAdicional: string;
  public etapasProcessamentoServico: string;
  public tempoEsperaAtendimento: number;
  public tempoEsperaAtendimentoTipo: string;
  public tempoMedioAtendimento: number;
  public tempoMedioAtendimentoTipo: string;
  public prazoRealizacao: number;
  public prazoRealizacaoTipo: string;
  public prazoMaximoRealizacao: number;
  public prazoMaximoRealizacaoTipo: string;
  public horariosAtendimento: string;
  public volumeAnualSolitacoes: number;
  public canalDigitalSolicitarServico: boolean  | string;
  public canalDigitalSolicitarServicoDescricao: string;
  public usoCpfFormulario: boolean | string;
  public tipoProjeto: string;
  public acao: string;
  public passivelTransformacao?: boolean;
  public editInTable?: boolean;
  public quantoTempoLeva: string;
  public horarios: HorarioServicoDto[];
  public podeEditar: boolean;
  public status: string;
  public dadosServicos: DadosServicoValidadoDto;
  public justificativas: JustificativaInfoDto[];
  public orgaoNome: string;
  public orgaoSite: string;
  public sede: string;
  public unidades: string;
  public cep: string;
  public logradouro: string;
  public numero: string;
  public telefone: string;
  public email: string;
  public documentos: any[];
  public orgaoUrl: string;
  public publicoAlvos: any[];
  public consultaUnidadesFisicasServico: string;

  constructor(object?: any) {
    if (object) {
      this.id                                    = object.id;
      this.orgaoId                               = object.orgaoId;
      this.categoria                             = object.categoria;
      this.servicoOferecido                      = object.servicoOferecido;
      this.descricaoServico                      = object.descricaoServico;
      this.tipoTaxa                              = object.tipoTaxa;
      this.taxas                                 = object.taxas;
      this.publicoAlvo                           = object.publicoAlvo;
      this.formaPrestacao                        = object.formaPrestacao;
      this.formaPrestacaoUrl                     = object.formaPrestacaoUrl;
      this.formasPrestacaoOnline                 = object.formasPrestacaoOnline;
      this.formaPrestacaoEditavel                = object.formaPrestacaoEditavel;
      this.andamentoDigital                      = object.andamentoDigital;
      this.exigencias                            = object.exigencias;
      this.informacaoAdicional                   = object.informacaoAdicional;
      this.etapasProcessamentoServico            = object.etapasProcessamentoServico;
      this.tempoEsperaAtendimento                = object.tempoEsperaAtendimento;
      this.tempoEsperaAtendimentoTipo            = object.tempoEsperaAtendimentoTipo;
      this.tempoMedioAtendimento                 = object.tempoMedioAtendimento;
      this.tempoMedioAtendimentoTipo             = object.tempoMedioAtendimentoTipo;
      this.tempoEsperaAtendimentoTipo            = object.tempoEsperaAtendimentoTipo;
      this.prazoRealizacao                       = object.prazoRealizacao;
      this.prazoRealizacaoTipo                   = object.prazoRealizacaoTipo;
      this.prazoMaximoRealizacao                 = object.prazoMaximoRealizacao;
      this.prazoMaximoRealizacaoTipo             = object.prazoMaximoRealizacaoTipo;
      this.horariosAtendimento                   = object.horariosAtendimento;
      this.canalDigitalSolicitarServico          = Util.stringToBoolean(object.canalDigitalSolicitarServico);
      this.canalDigitalSolicitarServicoDescricao = object.canalDigitalSolicitarServicoDescricao;
      this.usoCpfFormulario                      = Util.stringToBoolean(object.usoCpfFormulario);
      this.acao                                  = object.acao;
      this.tipoProjeto                           = object.tipoProjeto;
      this.volumeAnualSolitacoes                 = object.volumeAnualSolitacoes;
      this.passivelTransformacao                 = object.passivelTransformacao;
      this.editInTable                           = object.editInTable;
      this.horarios                              = object.horarios;
      this.quantoTempoLeva                       = object.quantoTempoLeva;
      this.podeEditar                            = object.podeEditar;
      this.status                                = object.status;
      this.dadosServicos                         = object.dadosValidacao;
      this.justificativas                        = object.justificativas;
      this.orgaoNome                             = object.orgaoNome;
      this.orgaoSite                             = object.orgaoSite;
      this.sede                                 = object.sede;
      this.unidades                             = object.unidades;
      this.cep                                  = object.cep;
      this.logradouro                           = object.logradouro;
      this.numero                               = object.numero;
      this.telefone                             = object.telefone;
      this.email                                = object.email;
      this.documentos                           = object.documentos;
      this.orgaoUrl                             = object.orgaoUrl;
      this.publicoAlvos                         = object.publicoAlvos;
      this.consultaUnidadesFisicasServico       = object.consultaUnidadesFisicasServico;
    }
  }
}
