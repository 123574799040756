import { MembrosComissaoService } from './membros-comissao.service';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ComponentEventHandler } from 'src/app/core/utils/component-event-handler';
import { OrgaoAction } from 'src/app/core/enums/action/orgao-action';

import { OrgaoService } from './orgao.service';
import { MethodEnum } from 'src/app/core/enums/method.enum';
import { ValidatorsCustom } from 'src/app/shared/utils/validators-custom';
import { ServicoOrgaoService } from './servico-orgao.service';

import { ServicoOrgaoUpdateDto } from 'src/app/core/dto/orgao/input/update/servico-orgao-update-dto';
import { ServicoTableDto } from 'src/app/core/dto/orgao/view/servico-table-dto';

@Injectable({
  providedIn: 'root',
})
export class OrgaoHandlerService extends ComponentEventHandler<
  OrgaoAction,
  any
> {
  public detalhamentoServico: ServicoOrgaoUpdateDto =
    new ServicoOrgaoUpdateDto();
  public detalhamentoServicoTable: Array<ServicoTableDto> = [];
  private _orgaoId: string;
  private _finish: boolean;
  private _orgaoName: string;

  constructor(
    private orgaoService: OrgaoService,
    private servicoOrgao: ServicoOrgaoService
  ) {
    super();
  }

  public set setOrgaoId(value: string) {
    this._orgaoId = value;
  }

  public get getOrgaoId(): string {
    return this._orgaoId;
  }

  public set setFinish(value: boolean) {
    this._finish = value;
  }

  public get getFinish(): boolean {
    return this._finish;
  }

  public set setOrgaoName(value) {
    this._orgaoName = value;
  }

  public get getOrgaoName(): string {
    return this._orgaoName;
  }

  public searchOrgaoId(value: string): Observable<any> {
    this.setOrgaoId = value;
    return this.orgaoService.getById(this._orgaoId);
  }

  public putDetalhamentoServico(
    value: any,
    isFinalizar?: boolean,
    arr?: Array<string>,
    arr2?: Array<string>,
    isEdit?: boolean,
    formasPrestacaoOnlineArray?: Array<string>,
    documentos?: any[],
    publicoAlvos?: any[]
  ): Observable<any> {
    if (arr2) {
      value['formaPrestacao'] = arr2;
      value['formasPrestacaoOnline'] = formasPrestacaoOnlineArray;
    }

    if (Array.isArray(value)) {
      value.map((el) => {
        this.detalhamentoServico.isFinalizar = isFinalizar;
        this.detalhamentoServico.orgaoId = this.getOrgaoId;
        this.detalhamentoServico.servicos.push(new ServicoTableDto(el));
      });
    }

    if (!Array.isArray(value)) {
      this.detalhamentoServico.isFinalizar = isFinalizar;
      this.detalhamentoServico.orgaoId = value.orgaoId;
      this.detalhamentoServico.servicos.push(new ServicoTableDto(value));
    }

    this.detalhamentoServico.servicos.map((el) => {
      if (
        ValidatorsCustom.isNullOrUndefined(
          el.taxasServico || el.taxasServico.length <= 0
        )
      ) {
        delete el.taxasServico;
      }
      if (ValidatorsCustom.isNullOrUndefined(el.formaPrestacaoUrl)) {
        delete el.formaPrestacaoUrl;
      }
      if (
        ValidatorsCustom.isNullOrUndefined(
          el.canalDigitalSolicitarServicoDescricao
        )
      ) {
        delete el.canalDigitalSolicitarServicoDescricao;
      }

      if (el.id && el.acao !== MethodEnum.EXC) {
        el.acao = MethodEnum.EDIT;
      }

      if (el.id && el.acao !== MethodEnum.EXC && isFinalizar) {
        el.acao = MethodEnum.NOTHING;
      }
    });
    if (isEdit) {
      return this.servicoOrgao.update(this.detalhamentoServico);
    } else {
      return this.servicoOrgao.create(this.detalhamentoServico);
    }
  }
}
