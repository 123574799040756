import { AfterViewInit, Directive, ElementRef, OnDestroy, OnInit, ViewChildren } from '@angular/core';
import {
  FormArray,
  FormControl, FormControlName,
  FormGroup
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxPermissionsService } from 'ngx-permissions';
import { fromEvent, merge, Observable, Subscription } from 'rxjs';
import { Util } from 'src/app/shared/utils/util';
import { ButtonSubmit } from '../../core/models/button-submit.model';
import { ValidateMessage } from '../../core/models/validate-message.model';
import { LayoutService } from './../../core/services/layout.service';
import { GenericValidatorForm } from './generic-validator-form';


@Directive()
export class FormBase implements OnInit, AfterViewInit, OnDestroy {
  @ViewChildren(FormControlName, { read: ElementRef })
  formInputElements: ElementRef[];
  subscribes: Subscription[] = [];

  public nameScreen = '';
  public pageTitle = '';
  public pageId = '';
  public typePage = '';
  public order: boolean = false;
  public collumnName: string;

  // Utilizados para validação e submissão
  public validateMessage = new ValidateMessage();
  public buttonSubmit = new ButtonSubmit();
  public form: FormGroup;
  public restrictionsSystem = Util.getRestricoes();

  constructor(
    public router: Router,
    public activatedRoute: ActivatedRoute,
    public validateMessages?: any,
    public permissionsService?: NgxPermissionsService
  ) {
    this.getParamsScreen();
  }

  ngOnInit(): void {
    this.newSubscribe = LayoutService.changePageTitle$.subscribe(title => this.pageTitle = title);
  }

  ngOnDestroy(): void {
    for(let subscribe of this.subscribes) {
      subscribe.unsubscribe();
    }
  }

  set newSubscribe(subscribe: Subscription) {
    this.subscribes.push(subscribe);
  }

  setPageTitle(title: string) {
    LayoutService.changePageTitle$.emit(title);
  }

  setRestrictionInComponent() {
    const restrictionsSystem = Util.getRestricoes();
    this.permissionsService.loadPermissions(restrictionsSystem);
  }

  setRestrictionInComponentWithRedirect(restrictions: string[]) {
    // this.permissionsService.loadPermissions(this.restrictionsSystem);

    const existRestriction = this.restrictionsSystem.filter(
      (item) => restrictions.indexOf(item) >= 0
    );
    if (existRestriction.length > 0) {
      return this.router.navigate(['/sem-permissao']);
    }
  }

  hiddenComponenteWithAll(restrictions: string[]) {
    return !restrictions
      .every(restriction => this.restrictionsSystem.includes(restriction));
  }

  hasAnyRestriction(restrictions: string[]) {
    return this.restrictionsSystem
      .some(restriction => restrictions.includes(restriction));
  }

  ngAfterViewInit(): void {
    this.controlsBlurValidate();
    this.createValidateFields();
    if(this.pageTitle){
      this.setPageTitle(this.pageTitle);
    }
  }

  /**
   * Função que obtem a ação a ser executada
   */
  public getParamsScreen(): void {
    this.pageId = this.activatedRoute.snapshot.params.id;
    this.nameScreen = Util.getScreenName(this.pageId);
  }

  /**
   * Função que realiza a validação por Blur
   */
  public controlsBlurValidate(): void {
    const controlBlurs: Observable<any>[] = this.formInputElements.map(
      (formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur')
    );
    merge(...controlBlurs).subscribe((value) => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(this.form);
    });
  }

  /**
   * Seta a classe de erro no campo
   * @param field Campo a ser realizado a tratativa
   */
  public setErrorValidate(field) {
    return Util.setErrorsValidate(
      this.form,
      this.validateMessage.messageDisplay,
      field
    );
  }

  /**
   * Função que habilita/desabilita o botão de salvar
   * verificando ser o form é valido
   */
  public enableShipping(): boolean {
    if (this.form.valid && !this.buttonSubmit.buttonSubmited) {
      return false;
    }
    return true;
  }

  /**
   * Utilizados no autocomplete
   */
  public updateErrors(form?) {
    setTimeout(() => {
      this.validateMessage.messageDisplay =
        this.validateMessage.genericValidator.processMessages(form ?? this.form);
    }, 100);
  }

  /**
   * Mensagens utilizadas na validação
   */
  public createValidateFields() {
    this.validateMessage.validationMessages = this.validateMessages
      ? this.validateMessages
      : {};
    this.validateMessage.genericValidator = new GenericValidatorForm(
      this.validateMessage.validationMessages
    );
  }

  /**
   * Função que força o processamento das mensagens vindo do input child
   */
  public forceProcessMessages() {
    this.validateMessage.messageDisplay =
      this.validateMessage.genericValidator.processMessages(this.form);
  }

  /**
   * Função que força como Dirty os campos do Form Group
   */
  markTouched(form: FormGroup) {
    this.markGroupTouched(form);
  }

  markGroupTouched(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach((key) => {
      switch (formGroup.get(key).constructor.name) {
        case 'FormGroup':
          this.markGroupTouched(formGroup.get(key) as FormGroup);
          break;
        case 'FormArray':
          this.markArrayTouched(formGroup.get(key) as FormArray);
          break;
        case 'FormControl':
          this.markControlTouched(formGroup.get(key) as FormControl);
          break;
      }
    });
  }

  markArrayTouched(formArray: FormArray) {
    formArray.controls.forEach((control) => {
      switch (control.constructor.name) {
        case 'FormGroup':
          this.markGroupTouched(control as FormGroup);
          break;
        case 'FormArray':
          this.markArrayTouched(control as FormArray);
          break;
        case 'FormControl':
          this.markControlTouched(control as FormControl);
          break;
      }
    });
  }
  markControlTouched(formControl: FormControl) {
    formControl.markAsTouched();
  }

  public choosePageUpdateOrView(): void {
    this.typePage = this.activatedRoute.snapshot.paramMap.get('type');
    if (this.typePage === 'visualizar') {
      this.form.disable();
    }
  }

  get isView(){
    return  this.router.url.includes('detalhes') || this.router.url.includes('visualizar');
  }

  get isAdicionar(){
    return this.router.url.includes('adicionar') || this.router.url.includes('cadastrar');
  }

  get isCadastrar(){
    return this.router.url.includes('cadastrar');
  }

  get isEditar(){
    return this.router.url.includes('editar');
  }

  get isDuplicar() {
    return this.router.url.includes('duplicar');
  }

  get isSubmeter() {
    return this.router.url.includes('submeter');
  }

  get isAvaliar(){
    return this.router.url.includes('avaliar');
  }

  get isAjustar(){
    return this.router.url.includes('ajustar');
  }

  get isList() {
    return !(this.isView || this.isAdicionar || this.isEditar || this.isDuplicar || this.isSubmeter);
  }

  getClassError(controlName: string, formGroup = this.form) {
    const control = formGroup.get(controlName);
    return control.invalid && (control.touched || control.dirty) ? 'is-invalid' : '';
  }

  getMessagesFormArray(controlName: string, formGroup = this.form) {
    const messages = this.validateMessage.genericValidator.processMessages(formGroup);
    return messages[controlName];
  }
}
